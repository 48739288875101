import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { GlobalContext } from '../../../context/GlobalState';
import GeographyFiltersComponent from './GeographyFilters';
import GeographyTablesComponent from './GeographyTables';
import LoaderComponent from '../../common/LoaderComponent/LoaderComponent';

export default function GeographyComponent() {
    const { finishedGeographyByCountyApi,finishedGeographyByGrowerDistrictApi } = useContext(GlobalContext);
    if(finishedGeographyByCountyApi==false||finishedGeographyByGrowerDistrictApi==false){
        document.body.style.opacity=.5;
      }else{
        document.body.style.opacity=1;
      }
    return (
        <div>
            {(finishedGeographyByCountyApi==false||finishedGeographyByGrowerDistrictApi==false)  && <LoaderComponent></LoaderComponent>}
            <GeographyFiltersComponent></GeographyFiltersComponent>
            <GeographyTablesComponent></GeographyTablesComponent>
        </div>
    );
}