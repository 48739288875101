import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { GlobalContext } from '../../../context/GlobalState';
import LegislatorsFiltersComponent from './LegislatorsFilters';
import LegislatorsTableComponent from './LegislatorsTable';
import LoaderComponent from '../../common/LoaderComponent/LoaderComponent';
import './LegislatorsComponent.css'

export default function FiltersComponent() {
    const { finishedLegislatorsApi,finishedDashboardApi } = useContext(GlobalContext);
     if(finishedLegislatorsApi==false||finishedDashboardApi==false){
        document.body.style.opacity=.5;
      }else{
        document.body.style.opacity=1;
      }
    return (
        <div>
            {(finishedLegislatorsApi==false||finishedDashboardApi==false)  && <LoaderComponent></LoaderComponent>}
            <LegislatorsFiltersComponent></LegislatorsFiltersComponent>
            <LegislatorsTableComponent></LegislatorsTableComponent>          
        </div>
    );
}