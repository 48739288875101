import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { GlobalContext } from '../../../context/GlobalState';
import CampaignDataComponent from './CampaignDataComponent';
import CampaignResponseDataComponent from './CampaignResponseDataComponent';
import TagsAnyComponent from './TagsAny';
import TagsAllResponseDispositionComponent from './TagsAllResponseDisposition';
import './CampaignComponent.css';
import LoaderComponent from '../../common/LoaderComponent/LoaderComponent';

export default function EntryComponent() {
    const { finishedCampaignDataApi,finishedCampaignResponseApi,finishedTagsAnyApi, finishedTagsAllApi, finishedResponseDispositionApi} = useContext(GlobalContext);
    if(finishedCampaignDataApi==false||finishedCampaignResponseApi==false||finishedTagsAnyApi==false||finishedTagsAllApi==false||finishedResponseDispositionApi==false){
        document.body.style.opacity=.5;
      }else{
        document.body.style.opacity=1;
      }
    return (
        <div>
            {(finishedCampaignDataApi==false||finishedCampaignResponseApi==false||finishedTagsAnyApi==false||finishedTagsAllApi==false||finishedResponseDispositionApi==false)  && <LoaderComponent></LoaderComponent>}
            <Row className="boxPadding">
                 <CampaignDataComponent></CampaignDataComponent>
                 <CampaignResponseDataComponent></CampaignResponseDataComponent>
                 <TagsAnyComponent></TagsAnyComponent> 
                 <TagsAllResponseDispositionComponent></TagsAllResponseDispositionComponent>               
            </Row>
        </div>
    );
}