import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Popup from 'reactjs-popup';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import { groupBy } from '../../../utility/utility';

import { GlobalContext } from '../../../context/GlobalState';

export default function TopCountComponent() {
    const { filters,finishedDashboardApi,setFinishedDashboardApi,setDashboardFilteredDataApi,setDashboardFilteredData,dashboardData, dashboardFilteredData, transactionDateList, expireDateList, statusList } = useContext(GlobalContext);
    const [topCounts, setTopCounts] = useState(
        {
            "total": 0,
            "active": 0,
            "lapsed": 0,
            "prospects": 0,
            "participant": 0,
        }
    )
    useEffect(() => {
        let localFilters = filters;
        if (localFilters.length != 0) {
            const filtersGroupedByKey = groupBy(localFilters, 'key');
            const filtersGroupByTable = groupBy(localFilters, 'filter');
            var newFilter = {};
            Object.keys(filtersGroupByTable).map((filterKey) => {
                let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                var newFilterByKey = {};
                Object.keys(filterGroupByKey).map((filKey) => {
                    let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                    newFilterByKey[filKey] = filterValues.toString();
                });
                newFilter[filterKey] = newFilterByKey;
            });
            setFinishedDashboardApi(false);
            setDashboardFilteredDataApi(newFilter);
        } else {
            if (dashboardData.length != 0) {
                setDashboardFilteredData(dashboardData);
            } else {
                setFinishedDashboardApi(false);
                setDashboardFilteredDataApi([]);
            }
        }
    }, []);
    useEffect(() => {
        let membersCount = dashboardFilteredData.reduce((sum, item) => sum += item.active + item.lapsed + item.prospects + item.participant, 0);
        console.log(dashboardFilteredData);
        if (membersCount !== topCounts.total) {
            let active = dashboardFilteredData.reduce((sum, item) => sum += item.active, 0);
            let lapsed = dashboardFilteredData.reduce((sum, item) => sum += item.lapsed, 0);
            let prospects = dashboardFilteredData.reduce((sum, item) => sum += item.prospects, 0);
            let participant = dashboardFilteredData.reduce((sum, item) => sum += item.participant, 0);           
            let total = active + lapsed + prospects + participant;
            setTopCounts({
                "total": total,
                "active": active,
                "lapsed": lapsed,
                "prospects": prospects,
                "participant": participant
            });
        }
    },[dashboardFilteredData]);
    const customerListExpireDate = {
        text: 'Expire Date', dataField: 'expiredate'
    };
    const customerListTransactionDate = {
        text: 'Transaction Date', dataField: 'transactiondate'
    };
    const customerListSource = {
        text: 'Source', dataField: 'contacttype_name'
    };
    return (
        <div>
            <Row style={{ 'marginBottom': '10px', 'marginTop': '50px' }}>
                <Col sm={3}>
                </Col>
                <Col sm={3} style={{ marginBottom: '20px' }}>
                    <Popup
                        trigger={<button className="form-control" style={{ width: '180px', 'margin-bottom': '10px', 'disabled': 'true' }}>Source</button>}
                        position="bottom middle"
                        nested
                        style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                    >

                        {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"topCounts"} close={close} column={customerListSource} columnOptionParent={statusList} /> </div>}

                    </Popup>
                </Col>
                <Col sm={3} style={{ marginBottom: '20px' }}>
                    <Popup
                        trigger={<button className="form-control" style={{ width: '180px', 'margin-bottom': '10px', 'disabled': 'true' }}>Expire Date</button>}
                        position="bottom middle"
                        nested
                        style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                    >

                        {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"topCounts"} close={close} column={customerListExpireDate} columnOptionParent={expireDateList} /> </div>}

                    </Popup>
                </Col>
                <Col sm={3}>
                    <Popup
                        trigger={<button className="form-control" style={{ width: '180px', 'margin-bottom': '10px', 'disabled': 'true' }}>Transaction Date</button>}
                        position="bottom middle"
                        nested
                        style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                    >

                        {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"topCounts"} close={close} column={customerListTransactionDate} columnOptionParent={transactionDateList} /> </div>}

                    </Popup>
                </Col>
                <Col sm={1}></Col>
                <Col sm={3}>
                    <div className="label1">Total</div>
                    <div className="labelValue colorBlack">{topCounts.total.toLocaleString()}</div>
                </Col>
                <Col sm={2}>
                    <div className="label1">Active</div>
                    <div className="labelValue colorLightBlue">{topCounts.active.toLocaleString()}</div>
                </Col>
                <Col sm={2}>
                    <div className="label1">Lapsed</div>
                    <div className="labelValue colorViolet">{topCounts.lapsed.toLocaleString()}</div></Col>
                <Col sm={2}>
                    <div className="label1">Prospects</div>
                    <div className="labelValue colorRed">{topCounts.prospects.toLocaleString()}</div></Col>
                <Col sm={2}>
                    <div className="label1">Participant</div>
                    <div className="labelValue colorYellow">{topCounts.participant.toLocaleString()}</div>
                </Col>
            </Row>
        </div>
    )
}