import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { GlobalContext } from '../../../context/GlobalState';
import TopCountComponent from './TopCounts';
import TopCountsTableChartComponent from './TopCountsTableChart';
import LoaderComponent from '../../common/LoaderComponent/LoaderComponent'

import './TopCountsComponent.css'

export default  function TopCountsComponent () {
    const { finishedDashboardApi } = useContext(GlobalContext);
    useEffect(() => {
        if(finishedDashboardApi==false){
            document.body.style.opacity=.5;
          }else{
            document.body.style.opacity=1;
          }
    });  
    return (
        <div>
            {finishedDashboardApi==false && <LoaderComponent></LoaderComponent>}             
            <TopCountComponent></TopCountComponent> 
            <TopCountsTableChartComponent></TopCountsTableChartComponent>          
        </div>
    )
}