import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Popup from 'reactjs-popup';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import axios from "axios";

import { GlobalContext } from '../../../context/GlobalState';

export default function GeographyFiltersComponent() {
    const { baseUrl,stateList, associationList,loggedinUser,setAssociationList,setStateList } = useContext(GlobalContext);
    const geographyStateList = {
        text: 'State', dataField: 'statename'
    };
    const geographyAssociationList = {
        text: 'Association', dataField: 'associationdescription'
    };
    useEffect(()=>{
        if (stateList.length == 0) {
            axios.post(baseUrl+"list_generator/states_list", loggedinUser.Client=="National Corn Growers Association" ? {} : { "topCounts": { "associationdescription": loggedinUser.Client } }).then(result => {
              setStateList(result.data.aaData);
            });
          }
          if (associationList.length == 0) {
            axios.post(baseUrl+"list_generator/association_list", loggedinUser.Client=="National Corn Growers Association" ? {} : { "topCounts": { "associationdescription": loggedinUser.Client } }).then(result => {
              setAssociationList(result.data.aaData);
            });
          }
    },[]);  
    return (
        <div>
            <Row className="boxPadding" style={{'marginBottom':'10px','marginTop':'50px'}}>               
                <Col sm={2} style={{marginBottom:'20px'}}>
                    <Popup
                        trigger={<button className="form-control" style={{ width: '180px', 'margin-bottom': '10px', 'disabled': 'true' }}>Filter By Association</button>}
                        position="bottom left"
                        nested
                        style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                    >

                        {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"geographyData"} close={close} column={geographyAssociationList} columnOptionParent={associationList} /> </div>}

                    </Popup>
                </Col>
                <Col sm={2} style={{marginBottom:'20px'}}>
                    <Popup
                        trigger={<button className="form-control" style={{ width: '180px', 'margin-bottom': '10px', 'disabled': 'true' }}>Filter By State</button>}
                        position="bottom left"
                        nested
                        style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                    >

                        {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"geographyData"} close={close} column={geographyStateList} columnOptionParent={stateList} /> </div>}

                    </Popup>
                </Col>               
            </Row>
        </div>
    )
}