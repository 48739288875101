import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import BootstrapTable from 'react-bootstrap-table-next';
import Popup from 'reactjs-popup';
import { Pie, PieChart, Cell, Tooltip } from 'recharts'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import { GlobalContext } from '../../../context/GlobalState';
import { groupBy } from '../../../utility/utility';

export default function TagsAllResponseDispositionComponent() {
    const { addFilter, addNewFilter, filters, removeFilter, removeNewFilter, setEntryIds,
        tagsAllData, tagsAllFilteredData, setTagsAllFilteredDataApi, setTagsAllFilteredData, setFinishedTagsAllApi,
        responseDispositionData, responseDispositionFilteredData, setResponseDispositionFilteredDataApi, setResponseDispositionFilteredData, setFinishedResponseDispositionApi,
    } = useContext(GlobalContext);
    const [filterCounts, setFilterCounts] = useState(0)
    function headerContent(column, colIndex, onFilter) {
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="top middle"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"tagsAllData"} close={close} column={column} columnOptionParent={tagsAllData} />
                    </div>}

                </Popup>
            </div>
        );
    }
    function headerContentResponseDisposition(column, colIndex, onFilter) {
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="top right"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"responseDispositionData"} close={close} column={column} columnOptionParent={responseDispositionData} />
                    </div>}

                </Popup>
            </div>
        );
    }
    const columnsTagAll = [
        {
            text: 'Tag', dataField: 'tag_all', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }, footer: 'Total'
        },
        {
            text: 'Total', dataField: 'tagalltotal', sort: true,formatter: (col, row)=>{return col.toLocaleString()}, footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        }
    ];
    const columnsResponseDisposition = [
        {
            text: 'Response Disposition', dataField: 'responsedisposition', sort: true, headerFormatter: headerContentResponseDisposition, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }, footer: 'Total'
        },
        {
            text: 'Total', dataField: 'responsedispositiontotal', sort: true, formatter: (col, row)=>{return col.toLocaleString()}, footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        }
    ];
    const defaultSortedTagAll = [{
        dataField: 'tag_all', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];
    const defaultSortedResponseDisposition = [{
        dataField: 'responsedisposition', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];
    useEffect(() => {
        let localFilters = filters;
        if (localFilters.length != 0) {
            const filtersGroupedByKey = groupBy(localFilters, 'key');
            const filtersGroupByTable = groupBy(localFilters, 'filter');
            var newFilter = {};
            Object.keys(filtersGroupByTable).map((filterKey) => {
                let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                var newFilterByKey = {};
                Object.keys(filterGroupByKey).map((filKey) => {
                    let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                    newFilterByKey[filKey] = filterValues.toString();
                });
                newFilter[filterKey] = newFilterByKey;
            });
            setFinishedTagsAllApi(false);
            setFinishedResponseDispositionApi(false);
            setTagsAllFilteredDataApi(newFilter);
            setResponseDispositionFilteredDataApi(newFilter);
        } else {
            if (tagsAllData.length != 0) {
                setTagsAllFilteredData(tagsAllData);
            } else {
                setFinishedTagsAllApi(false);
                setTagsAllFilteredDataApi([]);
            }
            if (responseDispositionData.length != 0) {
                setResponseDispositionFilteredData(responseDispositionData);
            } else {
                setFinishedResponseDispositionApi(false);
                setResponseDispositionFilteredDataApi([]);
            }
        }
    }, []);
    return (
        <div style={{ width: '50%' }}>
            <Col sm={6} style={{ float: 'left' }} id="tagsAll">
                <div id="tablediv" style={{ padding: '10px', paddingTop: "10px" }}>
                    <h5>Tags - all selected</h5>
                    <BootstrapTable
                        scrollX
                        keyField="name"
                        data={tagsAllFilteredData}
                        columns={columnsTagAll}
                        noDataIndication="There is no data"
                        width="200"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        // defaultSorted={defaultSortedTagAll}
                    />
                </div>
            </Col>
            <Col sm={6} style={{ float: 'left' }} id="responseDisposition">
                <div id="tablediv" style={{ padding: '10px', paddingTop: "10px" }}>
                    <h5>Response - Disposition</h5>
                    <BootstrapTable
                        scrollX
                        keyField="name"
                        data={responseDispositionFilteredData}
                        columns={columnsResponseDisposition}
                        noDataIndication="There is no data"
                        width="200"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        // defaultSorted={defaultSortedResponseDisposition}
                    />
                </div>
            </Col>
        </div>
    )
}