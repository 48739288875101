import React, { useContext, useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { Container, Row, Col } from 'react-grid-system';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import DataTable from 'react-data-table-component';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Popup from 'reactjs-popup';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import { XAxis, Tooltip, CartesianGrid, Bar, BarChart, Legend, YAxis, Cell, Brush } from 'recharts';

import { GlobalContext } from '../../../context/GlobalState';
import { groupByCount } from '../../../utility/utility';
import { groupBy } from '../../../utility/utility';

export default function TopCountsTableChartComponent() {

    const { dashboardFilteredData, filters, removeFilter, removeNewFilter, setEntryIds, addFilter, addNewFilter, dashboardData, setDashboardFilteredData, setDashboardFilteredDataApi, setFinishedDashboardApi } = useContext(GlobalContext);
    const [barChartData, setBarChartData] = useState([]);
    const [tableData, setTableData] = useState([])
    const [topCounts, setTopCounts] = useState(0);
    const [filterCounts, setFilterCounts] = useState(0);
    const [colorBarChart,setColorBarChart]=useState([]);
    const colorsBarChart = ["rgb(3, 169, 244)", "rgb(142, 71, 125)", "rgb(249, 63, 23)", "rgb(243, 194, 0)"];
    function headerContent(column, colIndex, onFilter) {       
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="bottom middle"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"topCounts"} close={close} column={column} columnOptionParent={dashboardData} />
                    </div>}

                </Popup>
            </div>
        );
    }
    const columns = [
        {
            text: 'Association', dataField: 'associationdescription', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Active', dataField: 'active',style:{color:"rgb(3, 169, 244)"}, sort: true,formatter: (col, row)=>{return col.toLocaleString()}
        },
        {
            text: 'Lapsed', dataField: 'lapsed',style:{color:"rgb(142, 71, 125)"}, sort: true ,formatter: (col, row)=>{return col.toLocaleString()}
        },
        {
            text: 'Prospects', dataField: 'prospects',style:{color:"rgb(249, 63, 23)"}, sort: true ,formatter: (col, row)=>{return col.toLocaleString()}
        },
        {
            text: 'Participant', dataField: 'participant',style:{color:"rgb(243, 194, 0)"}, sort: true ,formatter: (col, row)=>{return col.toLocaleString()}
        }
    ];
    const options = {

        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: 'All', value: tableData.length },
        ],
        
        sizePerPage: 12,
        pageStartIndex: 0,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
    }
    const DataFormater = (number) => {
        if(number > 1000000000){
          return (number/1000000000).toString() + 'B';
        }else if(number > 1000000){
          return (number/1000000).toString() + 'M';
        }else if(number > 1000){
          return (number/1000).toString() + 'K';
        }else{
          return number.toString();
        }
      }
    useEffect(() => {
        let active = dashboardFilteredData.reduce((sum, item) => sum += item.active, 0);
        let lapsed = dashboardFilteredData.reduce((sum, item) => sum += item.lapsed, 0);
        let prospects = dashboardFilteredData.reduce((sum, item) => sum += item.prospects, 0);
        let participant = dashboardFilteredData.reduce((sum, item) => sum += item.participant, 0);
        let total = active + lapsed + prospects + participant;
        if (total != topCounts) {
            let finalBarChartData = [
                { "name": "Active", "value": active },
                { "name": "Lapsed", "value": lapsed },
                { "name": "Prospects", "value": prospects },
                { "name": "Participant", "value": participant },
            ];
            finalBarChartData = [];
            let finalColors=[];
            if (active != 0) { finalBarChartData.push({ "name": "Active", "value": active });finalColors.push("rgb(3, 169, 244)"); };
            if (lapsed != 0) { finalBarChartData.push({ "name": "Lapsed", "value": lapsed });finalColors.push("rgb(142, 71, 125)"); };
            if (prospects != 0) { finalBarChartData.push({ "name": "Prospects", "value": prospects });finalColors.push("rgb(249, 63, 23)"); };
            if (participant != 0) { finalBarChartData.push({ "name": "Participant", "value": participant });finalColors.push("rgb(243, 194, 0)"); };            
            setBarChartData(finalBarChartData);
            setTopCounts(total);           
            setTableData(dashboardFilteredData);
            setColorBarChart(finalColors);
        }
    },[dashboardFilteredData])
    const filterChart = (name, key) => {
        if (name != "brush") {          
            let localFilter = filters;
            filters.map((filter) => {
                if (filter.filter == 'topCounts' && filter.key == key) {
                    removeFilter(filter.key);
                    removeNewFilter(filter.key);
                    localFilter = localFilter.filter(item => (item.key !== filter.key));
                }
            });
            addFilter({ key: key, value: name, filter: 'topCounts' });
            addNewFilter({ key: key, value: name, filter: 'topCounts' });
            localFilter.push({ key: key, value: name, filter: 'topCounts' });
            setFilterCounts(filterCounts + 1);
            if (localFilter.length != 0) {               
                const filtersGroupedByKey = groupBy(localFilter, 'key');
                const filtersGroupByTable = groupBy(localFilter, 'filter');
                var newFilter = {};
                Object.keys(filtersGroupByTable).map((filterKey) => {
                    let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                    var newFilterByKey = {};
                    Object.keys(filterGroupByKey).map((filKey) => {
                        let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                        newFilterByKey[filKey] = filterValues.toString();
                    });
                    newFilter[filterKey] = newFilterByKey;
                });
                setFinishedDashboardApi(false);              
                setDashboardFilteredDataApi(newFilter);               
            } else {
                setDashboardFilteredData(dashboardData);                
            }
        }
    }
    return (
        <div>
            <Row className="boxPadding">
                <Col sm={7}>
                    <div id="tablediv" style={{ padding: '20px',paddingBottom: '0px', paddingTop: "40px" }}>
                        <BootstrapTable
                            scrollX
                            keyField="name"
                            data={tableData}
                            columns={columns}
                            pagination={paginationFactory(options)}
                            noDataIndication="There is no data"
                            width="200"
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        />
                    </div>
                </Col>
                <Col sm={5}>
                    <h4 className="textalignleft">Total count by source</h4>
                    <BarChart width={500} height={500} data={barChartData} onClick={(e) => { filterChart(e == null ? "brush" : e.activeLabel, 'contacttype_name') }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" textAnchor="end" tick={{ angle: -15 }} interval={0} height={75} style={{ height: '200px', fontSize: '14px' }} />
                        <YAxis tickFormatter={DataFormater} />
                        <Tooltip formatter={(value) => 
                            // new Intl.NumberFormat('en').format(value)
                            DataFormater(value)
                            } />
                        <Bar dataKey="value" name="Voucher">
                            {barChartData.map((entry, index) => <Cell fill={colorBarChart[index % colorBarChart.length]} />)}
                        </Bar>
                    </BarChart>
                </Col>}
             </Row>
        </div>
    )
}