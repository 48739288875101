import React, { useContext, useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { Container, Row, Col } from 'react-grid-system';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import DataTable from 'react-data-table-component';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Popup from 'reactjs-popup';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import { XAxis, Tooltip, CartesianGrid, Bar, BarChart, Legend, YAxis, Cell, Brush } from 'recharts';

import { GlobalContext } from '../../../context/GlobalState';
import { groupByCount } from '../../../utility/utility';
import { groupBy } from '../../../utility/utility';

export default function LegislatorsTableComponent() {
    const { setFinishedDashboardApi,setDashboardFilteredDataApi,filters,setFinishedGeographyByCountyApi,setFinishedGeographyByGrowerDistrictApi,setGeographyByCountyFilteredDataApi,setGeographyByGrowerDistrictFilteredDataApi,setDashboardFilteredData,dashboardData,legislatorsData, legislatorsFilteredData, setLegislatorsFilteredDataApi, setLegislatorsFilteredData, setFinishedLegislatorsApi } = useContext(GlobalContext);
    function headerContent(column, colIndex, onFilter) {
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="bottom middle"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"legislatorsData"} close={close} column={column} columnOptionParent={legislatorsData} />
                    </div>}

                </Popup>
            </div>
        );
    }
    const columns = [
        {
            text: 'Level', dataField: 'legislator_level', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Branch', dataField: 'legislator_branch', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'State', dataField: 'legislator_statename', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'District', dataField: 'legislator_district', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'First Name', dataField: 'legislator_first_name', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Middle Initial', dataField: 'legislator_middle_initial', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Last Name', dataField: 'legislator_last_name', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Party', dataField: 'legislator_party', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        }
    ];
    const options = {

        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: 'All', value: legislatorsData.length },
        ],
        sizePerPage: 15,
        pageStartIndex: 0,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
    }
    useEffect(() => {
        let localFilters = filters;
        if (localFilters.length != 0) {
            const filtersGroupedByKey = groupBy(localFilters, 'key');
            const filtersGroupByTable = groupBy(localFilters, 'filter');
            var newFilter = {};
            Object.keys(filtersGroupByTable).map((filterKey) => {
                let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                var newFilterByKey = {};
                Object.keys(filterGroupByKey).map((filKey) => {
                    let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                    newFilterByKey[filKey] = filterValues.toString();
                });
                newFilter[filterKey] = newFilterByKey;
            });
            setFinishedLegislatorsApi(false);
            setLegislatorsFilteredDataApi(newFilter);
            setFinishedDashboardApi(false);
            setDashboardFilteredDataApi(newFilter);
        } else {
            if (legislatorsData.length != 0) {
                setLegislatorsFilteredData(legislatorsData);
            } else {
                setFinishedLegislatorsApi(false);
                setLegislatorsFilteredDataApi([]);
            }
            setDashboardFilteredData(dashboardData);
        }
    }, []);
    return (
        <div>
            <Row>
                <Col sm={12}>
                    <div id="tablediv" style={{ padding: '0px 20px 0px 20px' }}>
                        <h4 className="textalignleft">Legislators</h4>
                        <BootstrapTable
                            scrollX
                            keyField="name"
                            data={legislatorsFilteredData}
                            columns={columns}
                            pagination={paginationFactory(options)}
                            noDataIndication="There is no data"
                            width="200"
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )

}