import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { GlobalContext } from '../../../context/GlobalState';
import CornDemographicsComponent from './CornDemographics';
import SoybeanDemographicsComponent from './SoybeanDemographics';
import CattleDemographicsComponent from './CattleDemographics';
import HogsDemographicsComponent from './HogsDemographics';
import AgeDemographicsComponent from './AgeDemographics';
import './DemographicsComponent.css';
import LoaderComponent from '../../common/LoaderComponent/LoaderComponent';

export default function EntryComponent() {
    const { finishedCornDemoApi, finishedSoyDemoApi, finishedCattleDemoApi, finishedHogsDemoApi, finishedAgeDemoApi } = useContext(GlobalContext);
    if(finishedCornDemoApi==false || finishedSoyDemoApi==false || finishedCattleDemoApi==false || finishedHogsDemoApi==false || finishedAgeDemoApi==false){
        document.body.style.opacity=.5;
      }else{
        document.body.style.opacity=1;
      }
    return (
        <div>
            {(finishedCornDemoApi==false || finishedSoyDemoApi==false || finishedCattleDemoApi==false || finishedHogsDemoApi==false || finishedAgeDemoApi==false ) && <LoaderComponent></LoaderComponent>}
            <Row className="boxPadding">
                 <CornDemographicsComponent></CornDemographicsComponent>
                 <SoybeanDemographicsComponent></SoybeanDemographicsComponent>
                 <CattleDemographicsComponent></CattleDemographicsComponent>
                 <HogsDemographicsComponent></HogsDemographicsComponent>
                 <AgeDemographicsComponent></AgeDemographicsComponent>
            </Row>
        </div>
    );
}