import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { GlobalContext } from '../../../context/GlobalState';

export default function ExportCustomerCountComponent() {
    const {customerListValues, customerListFilteredValues, dashboardFilteredData } = useContext(GlobalContext);
    const [customerCounts, setCustomerCounts] = useState(
        {
            "total": 0,
            "active": 0,
            "lapsed": 0,
            "prospects": 0,
            "participant": 0,
        }
    )
    useEffect(() => {
        let membersCount = dashboardFilteredData.reduce((sum, item) => sum += item.active+item.lapsed+item.prospects+item.participant, 0);
        if (membersCount !== customerCounts.total) {
            let active = dashboardFilteredData.reduce((sum, item) => sum += item.active, 0);
            let lapsed = dashboardFilteredData.reduce((sum, item) => sum += item.lapsed, 0);
            let prospects = dashboardFilteredData.reduce((sum, item) => sum += item.prospects, 0);
            let participant = dashboardFilteredData.reduce((sum, item) => sum += item.participant, 0);
            //let participant = dashboardFilteredData.filter((e) => {return e["ContactType_Name"] === "Participant"}).length;           
            //let total =dashboardFilteredData.length;
            let total =active+lapsed+prospects+participant;
            setCustomerCounts({
                "total": total,
                "active":active,
                "lapsed":lapsed,
                "prospects":prospects,
                "participant":participant
            });
        }
    });
    return(
    <div>
        <Row style={{'marginBottom':'10px','marginTop':'10px'}}>                                  
            <Col sm={1}></Col>
            <Col sm={3}>
                <div className="label1">Total</div>
                <div className="labelValue colorBlack">{customerCounts.total.toLocaleString()}</div>
            </Col>               
            <Col sm={2}>
                <div className="label1">Active</div>
                <div className="labelValue colorBlack">{customerCounts.active.toLocaleString()}</div>
            </Col>
            <Col sm={2}>
                <div className="label1">Lapsed</div>
                <div className="labelValue colorBlack">{customerCounts.lapsed.toLocaleString()}</div></Col>
            <Col sm={2}>
                <div className="label1">Prospects</div>
                <div className="labelValue colorBlack">{customerCounts.prospects.toLocaleString()}</div></Col>
            <Col sm={2}>
                <div className="label1">Participant</div>
                <div className="labelValue colorBlack">{customerCounts.participant.toLocaleString()}</div>
            </Col>
        </Row>
    </div>
    )
}