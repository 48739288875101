import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import axios from "axios";

const initialState = {
    harvestValues: [],
    harvestFilteredValues: [],
    entryValues: [],
    entryFilteredValues: [],
    filters: [],
    tempFilters: [],
    tempFiltersUnchecked: [],
    newFilters: [],
    count: 0,
    filteredIds: [],
    selectedTabValue: 0,
    loggedinUser: {},
    dashboardData: [],
    dashboardFilteredData: [],
    customerListValues: [],
    customerListFilteredValues: [],
    cornDemographicsData: [],
    cornDemographicsFilteredData: [],
    soybeanDemographicsData: [],
    soybeanDemographicsFilteredData: [],
    cattleDemographicsData: [],
    cattleDemographicsFilteredData: [],
    hogsDemographicsData: [],
    hogsDemographicsFilteredData: [],
    ageDemographicsData: [],
    ageDemographicsFilteredData: [],
    expireDateList: [],
    transactionDateList: [],
    statusList: [],
    campaignData: [],
    campaignFilteredData: [],
    campaignResponseData: [],
    campaignResponseFilteredData: [],
    tagsAnyData: [],
    tagsAnyFilteredData: [],
    tagsAllData: [],
    tagsAllFilteredData: [],
    responseDispositionData: [],
    responseDispositionFilteredData: [],
    associationList: [],
    stateList: [],
    geographyByCounty: [],
    geographyByGrowerDistrict: [],
    geographyByCountyFilteredData: [],
    geographyByGrowerDistrictFilteredData: [],
    legislatorsLevelList: [],
    legislatorsBranchList: [],
    legislatorsStateList: [],
    legislatorsDistrictList: [],
    legislatorsData: [],
    legislatorsFilteredData: [],
    tabValue: 0,
    loader: false,
    finishedDashboardApi: false,
    finishedCustomerListApi: true,
    finishedCornDemoApi: true,
    finishedSoyDemoApi: true,
    finishedCattleDemoApi: true,
    finishedHogsDemoApi: true,
    finishedAgeDemoApi: true,
    finishedCampaignDataApi: true,
    finishedCampaignResponseApi: true,
    finishedTagsAnyApi: true,
    finishedTagsAllApi: true,
    finishedResponseDispositionApi: true,
    finishedGeographyByCountyApi: true,
    finishedGeographyByGrowerDistrictApi: true,
    finishedLegislatorsApi: true,
    finishedExportApi: true,
    customerTableFilters: {},
    finishedDasboardDataLoad:false,
    baseUrl:"https://ncga-dashboard-api.mavapartners.com/mssql/",
    baseUrlDashboard:"https://ncga-dashboard-api.mavapartners.com/"
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);
    function removeFilter(filter) {
        dispatch({
            type: 'REMOVE_FILTER',
            payload: filter
        });
    };
    function removeTempFilter(tempFilters) {
        dispatch({
            type: 'REMOVE_TEMP_FILTER',
            payload: tempFilters
        });
    };
    function removeTempFilterUnchecked(tempFilters) {
        dispatch({
            type: 'REMOVE_TEMP_FILTER_UNCHECKED',
            payload: tempFilters
        });
    };
    function removeNewFilter(filter) {
        dispatch({
            type: 'REMOVE_NEW_FILTER',
            payload: filter
        });
    };

    function addFilter(filters) {
        dispatch({
            type: 'ADD_FILTER',
            payload: filters
        });
    };

    function addTempFilter(tempFilters) {
        dispatch({
            type: 'ADD_TEMP_FILTER',
            payload: tempFilters
        });
    };

    function addTempFilterUnchecked(tempFilters) {
        dispatch({
            type: 'ADD_TEMP_FILTER_UNCHECKED',
            payload: tempFilters
        });
    };

    function addNewFilter(filters) {
        dispatch({
            type: 'ADD_NEW_FILTER',
            payload: filters
        });
    };

    function clearFilters() {
        dispatch({
            type: 'CLEAR_FILTERS'
        });
    };

    function clearTempFilters() {
        dispatch({
            type: 'CLEAR_TEMP_FILTERS'
        });
    };

    function clearTempFiltersUnchecked() {
        dispatch({
            type: 'CLEAR_TEMP_FILTERS_UNCHECKED'
        });
    };

    function addTestCount(count) {
        dispatch({
            type: 'ADD_TEST_COUNT',
            payload: count
        });
    };

    function setHarvestData(harvestValues) {
        dispatch({
            type: 'SET_HARVEST_DATA',
            payload: harvestValues
        });
    };

    function setHarvestFilteredData(harvestFilteredValues) {
        dispatch({
            type: 'SET_HARVEST_FILTERED_DATA',
            payload: harvestFilteredValues
        });
    };

    function setEntryData(entryValues) {
        dispatch({
            type: 'SET_ENTRY_DATA',
            payload: entryValues
        });
    };

    function setEntryFilteredData(entryFilteredValues) {
        dispatch({
            type: 'SET_ENTRY_FILTERED_DATA',
            payload: entryFilteredValues
        });
    };

    function setEntryIds(ids) {
        dispatch({
            type: 'SET_ENTRY_IDS',
            payload: ids
        });
    };

    function setSelectedTabValue(tabValue) {
        dispatch({
            type: 'SET_SELECTED_TAB',
            payload: tabValue
        });
    }

    function setUser(loggedinUser) {
        dispatch({
            type: 'SET_USER',
            payload: loggedinUser
        });
    };

    function setCustomerListData(customerListValues) {
        dispatch({
            type: 'SET_CUSTOMER_LIST_DATA',
            payload: customerListValues
        });
    };

    function setCustomerListFilteredData(customerListFilteredValues) {
        dispatch({
            type: 'SET_CUSTOMER_LIST_FILTERED_DATA',
            payload: customerListFilteredValues
        });
    };

    async function setCustomerListFilteredDataApi(filters) {
        let filt = filters;
        if (filt.length != 0) {
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            filt["page"] = 1;
            filt["per_page"] = 15;
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filt["topCounts"]) {
                    filt["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            await axios.post(state.baseUrl+"list_generator/serverside_export_list", filt).then(result => {
                dispatch({
                    type: 'SET_CUSTOMER_LIST_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CUSTOMER_LIST_API',
                    payload: true
                });
            });
        } else {
            let newfilt = { page: 1, per_page: 15 };
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (newfilt["topCounts"]) {
                    newfilt["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            await axios.post(state.baseUrl+"list_generator/serverside_export_list", newfilt).then(result => {
                dispatch({
                    type: 'SET_CUSTOMER_LIST_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_CUSTOMER_LIST_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CUSTOMER_LIST_API',
                    payload: true
                });
            });
        }
    };

    async function setCustomerListDataApi() {
        let newfilt = { page: 1, per_page: 15 };
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            if (newfilt["topCounts"]) {
                newfilt["topCounts"]["associationdescription"] = state.loggedinUser.Client;
            } else {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
        }
        await axios.post(state.baseUrl+"list_generator/serverside_export_list", newfilt).then(result => {
            dispatch({
                type: 'SET_CUSTOMER_LIST_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_CUSTOMER_LIST_API',
                payload: true
            });
        });
    };
    async function setCornDemographicsFilteredDataApi(filters) {
        if (filters.length != 0) {
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            await axios.post(state.baseUrl+"list_generator/new_corn_demographics", filters).then(result => {
                dispatch({
                    type: 'SET_CORN_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CORN_DEMO_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/new_corn_demographics", newfilt).then(result => {
                dispatch({
                    type: 'SET_CORN_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_CORN_DEMOGRAPHICS_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CORN_DEMO_API',
                    payload: true
                });
            });
        }
    };
    async function setCornDemographicsDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/new_corn_demographics", newfilt).then(result => {
            dispatch({
                type: 'SET_CORN_DEMOGRAPHICS_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_CORN_DEMO_API',
                payload: true
            });
        });
    };
    function setCornDemographicsData(cornDemographicsData) {
        dispatch({
            type: 'SET_CORN_DEMOGRAPHICS_DATA',
            payload: cornDemographicsData
        });
    };
    function setCornDemographicsFilteredData(cornDemographicsFilteredData) {
        dispatch({
            type: 'SET_CORN_DEMOGRAPHICS_FILTERED_DATA',
            payload: cornDemographicsFilteredData
        })
    }
    function setSoybeanDemographicsData(soybeanDemographicsData) {
        dispatch({
            type: 'SET_SOYBEAN_DEMOGRAPHICS_DATA',
            payload: soybeanDemographicsData
        });
    };

    function setSoybeanDemographicsFilteredData(soybeanDemographicsFilteredData) {
        dispatch({
            type: 'SET_SOYBEAN_DEMOGRAPHICS_FILTERED_DATA',
            payload: soybeanDemographicsFilteredData
        });
    };
    async function setSoybeanDemographicsFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/new_soy_demographics", filters).then(result => {
                dispatch({
                    type: 'SET_SOYBEAN_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_SOY_DEMO_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/new_soy_demographics", newfilt).then(result => {
                dispatch({
                    type: 'SET_SOYBEAN_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_SOYBEAN_DEMOGRAPHICS_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_SOY_DEMO_API',
                    payload: true
                });
            });
        }
    };
    async function setSoybeanDemographicsDataApi(filters) {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/new_soy_demographics", newfilt).then(result => {
            dispatch({
                type: 'SET_SOYBEAN_DEMOGRAPHICS_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_SOY_DEMO_API',
                payload: true
            });
        });
    };
    function setCattleDemographicsData(cattleDemographicsData) {
        dispatch({
            type: 'SET_CATTLE_DEMOGRAPHICS_DATA',
            payload: cattleDemographicsData
        });
    };

    function setCattleDemographicsFilteredData(cattleDemographicsFilteredData) {
        dispatch({
            type: 'SET_CATTLE_DEMOGRAPHICS_FILTERED_DATA',
            payload: cattleDemographicsFilteredData
        });
    };
    async function setCattleDemographicsFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/new_cattle_demographics", filters).then(result => {
                dispatch({
                    type: 'SET_CATTLE_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CATTLE_DEMO_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/new_cattle_demographics", newfilt).then(result => {
                dispatch({
                    type: 'SET_CATTLE_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_CATTLE_DEMOGRAPHICS_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CATTLE_DEMO_API',
                    payload: true
                });
            });
        }
    };
    async function setCattleDemographicsDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/new_cattle_demographics", newfilt).then(result => {
            dispatch({
                type: 'SET_CATTLE_DEMOGRAPHICS_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_CATTLE_DEMO_API',
                payload: true
            });
        });
    };

    function setHogsDemographicsData(hogsDemographicsData) {
        dispatch({
            type: 'SET_HOGS_DEMOGRAPHICS_DATA',
            payload: hogsDemographicsData
        });
    };

    function setHogsDemographicsFilteredData(hogsDemographicsFilteredData) {
        dispatch({
            type: 'SET_HOGS_DEMOGRAPHICS_FILTERED_DATA',
            payload: hogsDemographicsFilteredData
        });
    };
    async function setHogsDemographicsFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/new_hogs_demographics", filters).then(result => {
                dispatch({
                    type: 'SET_HOGS_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_HOGS_DEMO_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/new_hogs_demographics", newfilt).then(result => {
                dispatch({
                    type: 'SET_HOGS_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_HOGS_DEMOGRAPHICS_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_HOGS_DEMO_API',
                    payload: true
                });
            });
        }
    };

    async function setHogsDemographicsDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/new_hogs_demographics", newfilt).then(result => {
            dispatch({
                type: 'SET_HOGS_DEMOGRAPHICS_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_HOGS_DEMO_API',
                payload: true
            });
        });
    };

    function setAgeDemographicsData(ageDemographicsData) {
        dispatch({
            type: 'SET_AGE_DEMOGRAPHICS_DATA',
            payload: ageDemographicsData
        });
    };

    function setAgeDemographicsFilteredData(ageDemographicsFilteredData) {
        dispatch({
            type: 'SET_AGE_DEMOGRAPHICS_FILTERED_DATA',
            payload: ageDemographicsFilteredData
        });
    };
    async function setAgeDemographicsFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/new_age_demographics", filters).then(result => {
                dispatch({
                    type: 'SET_AGE_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_AGE_DEMO_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/new_age_demographics", newfilt).then(result => {
                dispatch({
                    type: 'SET_AGE_DEMOGRAPHICS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_AGE_DEMOGRAPHICS_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_AGE_DEMO_API',
                    payload: true
                });
            });
        }
    };

    async function setAgeDemographicsDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/new_age_demographics", newfilt).then(result => {
            dispatch({
                type: 'SET_AGE_DEMOGRAPHICS_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_AGE_DEMO_API',
                payload: true
            });
        });
    };


    function setDashboardData(dashboardData) {
        dispatch({
            type: 'SET_DASHBOARD_DATA',
            payload: dashboardData
        });
    };

    function setDashboardFilteredData(dashboardFilteredData) {
        dispatch({
            type: 'SET_DASHBOARD_FILTERED_DATA',
            payload: dashboardFilteredData
        });
    };

    async function setDashboardFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/serverside_dashboard", filters).then(result => {
                dispatch({
                    type: 'SET_DASHBOARD_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_DASHBOARD_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/serverside_dashboard", newfilt).then(result => {
                dispatch({
                    type: 'SET_DASHBOARD_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_DASHBOARD_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_DASHBOARD_API',
                    payload: true
                });
            });
        }
    };

    async function setDashboardDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/serverside_dashboard", newfilt).then(result => {
            dispatch({
                type: 'SET_DASHBOARD_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_DASHBOARD_API',
                payload: true
            });
        });
    };


    function setTabData(tabValue) {
        dispatch({
            type: 'SET_TAB_DATA',
            payload: tabValue
        });
    };
    function setExpireDateList(expireDateList) {
        dispatch({
            type: 'SET_EXPIREDATE_LIST',
            payload: expireDateList
        });
    };
    function setTransactionDateList(transactionDateList) {
        dispatch({
            type: 'SET_TRANSACTIONDATE_LIST',
            payload: transactionDateList
        });
    };
    function setStatusList(statusList) {
        dispatch({
            type: 'SET_STATUS_LIST',
            payload: statusList
        });
    };
    function setLoader(loader) {
        dispatch({
            type: 'SET_LOADER',
            payload: loader
        });
    };
    function setFinishedAgeDemoApi(finishedAgeDemoApi) {
        dispatch({
            type: 'SET_FINISH_AGE_DEMO_API',
            payload: finishedAgeDemoApi
        });
    };
    function setFinishedCattleDemoApi(finishedCattleDemoApi) {
        dispatch({
            type: 'SET_FINISH_CATTLE_DEMO_API',
            payload: finishedCattleDemoApi
        });
    };
    function setFinishedCornDemoApi(finishedCornDemoApi) {
        dispatch({
            type: 'SET_FINISH_CORN_DEMO_API',
            payload: finishedCornDemoApi
        });
    };
    function setFinishedCustomerListApi(finishedCustomerListApi) {
        dispatch({
            type: 'SET_FINISH_CUSTOMER_LIST_API',
            payload: finishedCustomerListApi
        });
    };
    function setFinishedDashboardApi(finishedDashboardApi) {
        dispatch({
            type: 'SET_FINISH_DASHBOARD_API',
            payload: finishedDashboardApi
        });
    };
    function setFinishedHogsDemoApi(finishedHogsDemoApi) {
        dispatch({
            type: 'SET_FINISH_HOGS_DEMO_API',
            payload: finishedHogsDemoApi
        });
    };
    function setFinishedSoyDemoApi(finishedSoyDemoApi) {
        dispatch({
            type: 'SET_FINISH_SOY_DEMO_API',
            payload: finishedSoyDemoApi
        });
    };
    function setCampaignData(campaignData) {
        dispatch({
            type: 'SET_CAMPAIGN_DATA',
            payload: campaignData
        });
    };

    function setCampaignFilteredData(campaignFilteredData) {
        dispatch({
            type: 'SET_CAMPAIGN_FILTERED_DATA',
            payload: campaignFilteredData
        });
    };
    async function setCampaignFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/campaign_data", filters).then(result => {
                dispatch({
                    type: 'SET_CAMPAIGN_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CAMPAIGN_DATA_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/campaign_data", newfilt).then(result => {
                dispatch({
                    type: 'SET_CAMPAIGN_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_CAMPAIGN_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CAMPAIGN_DATA_API',
                    payload: true
                });
            });
        }
    };
    async function setCampaignDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/campaign_data", newfilt).then(result => {
            dispatch({
                type: 'SET_CAMPAIGN_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_CAMPAIGN_DATA_API',
                payload: true
            });
        });
    };
    function setFinishedCampaignDataApi(finishedCampaignDataApi) {
        dispatch({
            type: 'SET_FINISH_CAMPAIGN_DATA_API',
            payload: finishedCampaignDataApi
        });
    };

    function setCampaignResponseData(campaignResponseData) {
        dispatch({
            type: 'SET_CAMPAIGN_RESPONSE_DATA',
            payload: campaignResponseData
        });
    };

    function setCampaignResponseFilteredData(campaignResponseFilteredData) {
        dispatch({
            type: 'SET_CAMPAIGN_RESPONSE_FILTERED_DATA',
            payload: campaignResponseFilteredData
        });
    };
    async function setCampaignResponseFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/response_campaign_data", filters).then(result => {
                dispatch({
                    type: 'SET_CAMPAIGN_RESPONSE_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CAMPAIGN_RESPONSE_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/response_campaign_data", newfilt).then(result => {
                dispatch({
                    type: 'SET_CAMPAIGN_RESPONSE_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_CAMPAIGN_RESPONSE_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_CAMPAIGN_RESPONSE_API',
                    payload: true
                });
            });
        }
    };
    async function setCampaignResponseDataApi(filters) {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/response_campaign_data", newfilt).then(result => {
            dispatch({
                type: 'SET_CAMPAIGN_RESPONSE_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_CAMPAIGN_RESPONSE_API',
                payload: true
            });
        });
    };
    function setFinishedCampaignResponseApi(finishedCampaignResponseApi) {
        dispatch({
            type: 'SET_FINISH_CAMPAIGN_RESPONSE_API',
            payload: finishedCampaignResponseApi
        });
    };
    function setTagsAnyData(tagsAnyData) {
        dispatch({
            type: 'SET_TAGS_ANY_DATA',
            payload: tagsAnyData
        });
    };

    function setTagsAnyFilteredData(tagsAnyFilteredData) {
        dispatch({
            type: 'SET_TAGS_ANY_FILTERED_DATA',
            payload: tagsAnyFilteredData
        });
    };
    async function setTagsAnyFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/tag_any", filters).then(result => {
                dispatch({
                    type: 'SET_TAGS_ANY_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_TAGS_ANY_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/tag_any", newfilt).then(result => {
                dispatch({
                    type: 'SET_TAGS_ANY_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_TAGS_ANY_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_TAGS_ANY_API',
                    payload: true
                });
            });
        }
    };
    async function setTagsAnyDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/tag_any", newfilt).then(result => {
            dispatch({
                type: 'SET_TAGS_ANY_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_TAGS_ANY_API',
                payload: true
            });
        });
    };
    function setFinishedTagsAnyApi(finishedTagsAnyApi) {
        dispatch({
            type: 'SET_FINISH_TAGS_ANY_API',
            payload: finishedTagsAnyApi
        });
    };
    function setTagsAllData(tagsAllData) {
        dispatch({
            type: 'SET_TAGS_ALL_DATA',
            payload: tagsAllData
        });
    };

    function setTagsAllFilteredData(tagsAllFilteredData) {
        dispatch({
            type: 'SET_TAGS_ALL_FILTERED_DATA',
            payload: tagsAllFilteredData
        });
    };
    async function setTagsAllFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/tag_all", filters).then(result => {
                dispatch({
                    type: 'SET_TAGS_ALL_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_TAGS_ALL_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/tag_all", newfilt).then(result => {
                dispatch({
                    type: 'SET_TAGS_ALL_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_TAGS_ALL_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_TAGS_ALL_API',
                    payload: true
                });
            });
        }
    };
    async function setTagsAllDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/tag_all", newfilt).then(result => {
            dispatch({
                type: 'SET_TAGS_ALL_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_TAGS_ALL_API',
                payload: true
            });
        });
    };
    function setFinishedTagsAllApi(finishedTagsAllApi) {
        dispatch({
            type: 'SET_FINISH_TAGS_ALL_API',
            payload: finishedTagsAllApi
        });
    };
    function setResponseDispositionData(responseDispositionData) {
        dispatch({
            type: 'SET_RESPONSE_DISPOSITION_DATA',
            payload: responseDispositionData
        });
    };

    function setResponseDispositionFilteredData(responseDispositionFilteredData) {
        dispatch({
            type: 'SET_RESPONSE_DISPOSITION_FILTERED_DATA',
            payload: responseDispositionFilteredData
        });
    };
    async function setResponseDispositionFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/response_disposition", filters).then(result => {
                dispatch({
                    type: 'SET_RESPONSE_DISPOSITION_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_RESPONSE_DISPOSITION_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/response_disposition", newfilt).then(result => {
                dispatch({
                    type: 'SET_RESPONSE_DISPOSITION_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_RESPONSE_DISPOSITION_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_RESPONSE_DISPOSITION_API',
                    payload: true
                });
            });
        }
    };
    async function setResponseDispositionDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/response_disposition", newfilt).then(result => {
            dispatch({
                type: 'SET_RESPONSE_DISPOSITION_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_RESPONSE_DISPOSITION_API',
                payload: true
            });
        });
    };
    function setFinishedResponseDispositionApi(finishedResponseDispositionApi) {
        dispatch({
            type: 'SET_FINISH_RESPONSE_DISPOSITION_API',
            payload: finishedResponseDispositionApi
        });
    };
    function setAssociationList(expireDateList) {
        dispatch({
            type: 'SET_ASSOCIATION_LIST',
            payload: expireDateList
        });
    };
    function setStateList(transactionDateList) {
        dispatch({
            type: 'SET_STATE_LIST',
            payload: transactionDateList
        });
    };
    function setGeographyByCounty(geographyByCounty) {
        dispatch({
            type: 'SET_GEOGRAPHY_BY_COUNTY',
            payload: geographyByCounty
        });
    };

    function setGeographyByCountyFilteredData(geographyByCountyFilteredData) {
        dispatch({
            type: 'SET_GEOGRAPHY_BY_COUNTY_FILTERED_DATA',
            payload: geographyByCountyFilteredData
        });
    };
    async function setGeographyByCountyFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/geography_county", filters).then(result => {
                dispatch({
                    type: 'SET_GEOGRAPHY_BY_COUNTY_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_GEOGRAPHY_BY_COUNTY_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/geography_county", newfilt).then(result => {
                dispatch({
                    type: 'SET_GEOGRAPHY_BY_COUNTY_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_GEOGRAPHY_BY_COUNTY',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_GEOGRAPHY_BY_COUNTY_API',
                    payload: true
                });
            });
        }
    };
    async function setGeographyByCountyDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/geography_county", newfilt).then(result => {
            dispatch({
                type: 'SET_GEOGRAPHY_BY_COUNTY',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_GEOGRAPHY_BY_COUNTY_API',
                payload: true
            });
        });
    };
    function setFinishedGeographyByCountyApi(finishedGeographyByCountyApi) {
        dispatch({
            type: 'SET_FINISH_GEOGRAPHY_BY_COUNTY_API',
            payload: finishedGeographyByCountyApi
        });
    };
    function setGeographyByGrowerDistrict(geographyByGrowerDistrict) {
        dispatch({
            type: 'SET_GEOGRAPHY_BY_GROWER_DISTRICT',
            payload: geographyByGrowerDistrict
        });
    };

    function setGeographyByGrowerDistrictFilteredData(geographyByGrowerDistrictFilteredData) {
        dispatch({
            type: 'SET_GEOGRAPHY_BY_GROWER_DISTRICT_FILTERED_DATA',
            payload: geographyByGrowerDistrictFilteredData
        });
    };
    async function setGeographyByGrowerDistrictFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/geography_grower_district", filters).then(result => {
                dispatch({
                    type: 'SET_GEOGRAPHY_BY_GROWER_DISTRICT_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_GEOGRAPHY_BY_GROWER_DISTRICT_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/geography_grower_district", newfilt).then(result => {
                dispatch({
                    type: 'SET_GEOGRAPHY_BY_GROWER_DISTRICT_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_GEOGRAPHY_BY_GROWER_DISTRICT',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_GEOGRAPHY_BY_GROWER_DISTRICT_API',
                    payload: true
                });
            });
        }
    };
    async function setGeographyByGrowerDistrictDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/geography_grower_district", newfilt).then(result => {
            dispatch({
                type: 'SET_GEOGRAPHY_BY_GROWER_DISTRICT',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_GEOGRAPHY_BY_GROWER_DISTRICT_API',
                payload: true
            });
        });
    };
    function setFinishedGeographyByGrowerDistrictApi(finishedGeographyByGrowerDistrictApi) {
        dispatch({
            type: 'SET_FINISH_GEOGRAPHY_BY_GROWER_DISTRICT_API',
            payload: finishedGeographyByGrowerDistrictApi
        });
    };
    function setLegislatorsLevelList(legislatorsLevelList) {
        dispatch({
            type: 'SET_LEGISLATORS_LEVEL_LIST',
            payload: legislatorsLevelList
        });
    };
    function setLegislatorsBranchList(legislatorsBranchList) {
        dispatch({
            type: 'SET_LEGISLATORS_BRANCH_LIST',
            payload: legislatorsBranchList
        });
    };
    function setLegislatorsStateList(legislatorsStateList) {
        dispatch({
            type: 'SET_LEGISLATORS_STATE_LIST',
            payload: legislatorsStateList
        });
    };
    function setLegislatorsDistrictList(legislatorsDistrictList) {
        dispatch({
            type: 'SET_LEGISLATORS_DISTRICT_LIST',
            payload: legislatorsDistrictList
        });
    };
    function setLegislatorsData(legislatorsData) {
        dispatch({
            type: 'SET_LEGISLATORS_DATA',
            payload: legislatorsData
        });
    };

    function setLegislatorsFilteredData(legislatorsFilteredData) {
        dispatch({
            type: 'SET_LEGISLATORS_FILTERED_DATA',
            payload: legislatorsFilteredData
        });
    };
    async function setLegislatorsFilteredDataApi(filters) {
        if (filters.length != 0) {
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                if (filters["topCounts"]) {
                    filters["topCounts"]["associationdescription"] = state.loggedinUser.Client;
                } else {
                    filters["topCounts"] = { "associationdescription": state.loggedinUser.Client };
                }
            }
            // var filterObj = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
            await axios.post(state.baseUrl+"list_generator/legislator_data_list", filters).then(result => {
                dispatch({
                    type: 'SET_LEGISLATORS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_LEGISLATORS_API',
                    payload: true
                });
            });
        } else {
            let newfilt = {}
            if (state.loggedinUser.Client != "National Corn Growers Association") {
                newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
            }
            await axios.post(state.baseUrl+"list_generator/legislator_data_list", newfilt).then(result => {
                dispatch({
                    type: 'SET_LEGISLATORS_FILTERED_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_LEGISLATORS_DATA',
                    payload: result.data.aaData
                });
                dispatch({
                    type: 'SET_FINISH_LEGISLATORS_API',
                    payload: true
                });
            });
        }
    };
    async function setLegislatorsDataApi() {
        let newfilt = {}
        if (state.loggedinUser.Client != "National Corn Growers Association") {
            newfilt["topCounts"] = { "associationdescription": state.loggedinUser.Client };
        }
        await axios.post(state.baseUrl+"list_generator/legislator_data_list", newfilt).then(result => {
            dispatch({
                type: 'SET_LEGISLATORS_DATA',
                payload: result.data.aaData
            });
            dispatch({
                type: 'SET_FINISH_LEGISLATORS_API',
                payload: true
            });
        });
    };
    function setFinishedLegislatorsApi(finishedLegislatorsApi) {
        dispatch({
            type: 'SET_FINISH_LEGISLATORS_API',
            payload: finishedLegislatorsApi
        });
    };
    function setFinishedExportApi(finishedExportApi) {
        dispatch({
            type: 'SET_FINISH_EXPORT_API',
            payload: finishedExportApi
        });
    };
    function setFinishedDasboardDataLoad(finishedDasboardDataLoad) {
        dispatch({
            type: 'SET_FINISH_DASHBOARD_DATA_LOAD',
            payload: finishedDasboardDataLoad
        });
    };
    function setCustomerTableFilters(tableFilter) {
        let newCustomerTableFilters = state.customerTableFilters;
        newCustomerTableFilters[Object.keys(tableFilter)[0]] = Object.values(tableFilter)[0];
        dispatch({
            type: 'SET_CUSTOMER_TABLE_FILTERS',
            payload: newCustomerTableFilters
        });
    };
    return (<GlobalContext.Provider value={{
        filters: state.filters,
        newFilters: state.newFilters,
        entryValues: state.entryValues,
        entryFilteredValues: state.entryFilteredValues,
        harvestValues: state.harvestValues,
        harvestFilteredValues: state.harvestFilteredValues,
        count: state.count,
        filteredIds: state.filteredIds,
        selectedTabValue: state.selectedTabValue,
        loggedinUser: state.loggedinUser,
        dashboardData: state.dashboardData,
        dashboardFilteredData: state.dashboardFilteredData,
        customerListValues: state.customerListValues,
        customerListFilteredValues: state.customerListFilteredValues,
        cornDemographicsData: state.cornDemographicsData,
        cornDemographicsFilteredData: state.cornDemographicsFilteredData,
        soybeanDemographicsData: state.soybeanDemographicsData,
        soybeanDemographicsFilteredData: state.soybeanDemographicsFilteredData,
        cattleDemographicsData: state.cattleDemographicsData,
        cattleDemographicsFilteredData: state.cattleDemographicsFilteredData,
        hogsDemographicsData: state.hogsDemographicsData,
        hogsDemographicsFilteredData: state.hogsDemographicsFilteredData,
        ageDemographicsData: state.ageDemographicsData,
        ageDemographicsFilteredData: state.ageDemographicsFilteredData,
        expireDateList: state.expireDateList,
        transactionDateList: state.transactionDateList,
        statusList: state.statusList,
        associationList: state.associationList,
        stateList: state.stateList,
        tabValue: state.tabValue,
        loader: state.loader,
        finishedDashboardApi: state.finishedDashboardApi,
        finishedCustomerListApi: state.finishedCustomerListApi,
        finishedCornDemoApi: state.finishedCornDemoApi,
        finishedSoyDemoApi: state.finishedSoyDemoApi,
        finishedCattleDemoApi: state.finishedCattleDemoApi,
        finishedHogsDemoApi: state.finishedHogsDemoApi,
        finishedAgeDemoApi: state.finishedAgeDemoApi,
        finishedCampaignDataApi: state.finishedCampaignDataApi,
        finishedCampaignResponseApi: state.finishedCampaignResponseApi,
        finishedTagsAnyApi: state.finishedTagsAnyApi,
        finishedTagsAllApi: state.finishedTagsAllApi,
        finishedResponseDispositionApi: state.finishedResponseDispositionApi,
        finishedGeographyByCountyApi: state.finishedGeographyByCountyApi,
        finishedGeographyByGrowerDistrictApi: state.finishedGeographyByGrowerDistrictApi,
        finishedLegislatorsApi: state.finishedLegislatorsApi,
        finishedExportApi: state.finishedExportApi,
        finishedDasboardDataLoad: state.finishedDasboardDataLoad,
        campaignData: state.campaignData,
        campaignFilteredData: state.campaignFilteredData,
        campaignResponseData: state.campaignResponseData,
        campaignResponseFilteredData: state.campaignResponseFilteredData,
        tagsAnyData: state.tagsAnyData,
        tagsAnyFilteredData: state.tagsAnyFilteredData,
        tagsAllData: state.tagsAllData,
        tagsAllFilteredData: state.tagsAllFilteredData,
        responseDispositionData: state.responseDispositionData,
        responseDispositionFilteredData: state.responseDispositionFilteredData,
        geographyByCounty: state.geographyByCounty,
        geographyByGrowerDistrict: state.geographyByGrowerDistrict,
        geographyByCountyFilteredData: state.geographyByCountyFilteredData,
        geographyByGrowerDistrictFilteredData: state.geographyByGrowerDistrictFilteredData,
        legislatorsStateList: state.legislatorsStateList,
        legislatorsDistrictList: state.legislatorsDistrictList,
        legislatorsBranchList: state.legislatorsBranchList,
        legislatorsLevelList: state.legislatorsLevelList,
        legislatorsData: state.legislatorsData,
        legislatorsFilteredData: state.legislatorsFilteredData,
        customerTableFilters: state.customerTableFilters,
        tempFilters: state.tempFilters,
        tempFiltersUnchecked: state.tempFiltersUnchecked,
        baseUrl:state.baseUrl,
        baseUrlDashboard:state.baseUrlDashboard, 
        removeFilter,
        removeTempFilter,
        removeTempFilterUnchecked,
        addFilter,
        addTempFilter,
        addTempFilterUnchecked,
        setHarvestData,
        setEntryData,
        addTestCount,
        setEntryIds,
        setHarvestFilteredData,
        setEntryFilteredData,
        setSelectedTabValue,
        setUser,
        clearFilters,
        clearTempFilters,
        clearTempFiltersUnchecked,
        addNewFilter,
        removeNewFilter,
        setDashboardData,
        setDashboardFilteredData,
        setDashboardFilteredDataApi,
        setCustomerListData,
        setCustomerListFilteredData,
        setCustomerListFilteredDataApi,
        setCornDemographicsData,
        setCornDemographicsFilteredData,
        setCornDemographicsFilteredDataApi,
        setSoybeanDemographicsData,
        setSoybeanDemographicsFilteredData,
        setSoybeanDemographicsFilteredDataApi,
        setCattleDemographicsData,
        setCattleDemographicsFilteredData,
        setCattleDemographicsFilteredDataApi,
        setHogsDemographicsData,
        setHogsDemographicsFilteredData,
        setHogsDemographicsFilteredDataApi,
        setAgeDemographicsData,
        setAgeDemographicsFilteredData,
        setAgeDemographicsFilteredDataApi,
        setExpireDateList,
        setTransactionDateList,
        setStatusList,
        setTabData,
        setLoader,
        setFinishedDashboardApi,
        setFinishedCustomerListApi,
        setFinishedCornDemoApi,
        setFinishedSoyDemoApi,
        setFinishedCattleDemoApi,
        setFinishedHogsDemoApi,
        setFinishedAgeDemoApi,
        setCampaignData,
        setCampaignFilteredData,
        setCampaignFilteredDataApi,
        setFinishedCampaignDataApi,
        setCampaignResponseData,
        setCampaignResponseFilteredData,
        setCampaignResponseFilteredDataApi,
        setFinishedCampaignResponseApi,
        setTagsAnyData,
        setTagsAnyFilteredData,
        setTagsAnyFilteredDataApi,
        setFinishedTagsAnyApi,
        setTagsAllData,
        setTagsAllFilteredData,
        setTagsAllFilteredDataApi,
        setFinishedTagsAllApi,
        setResponseDispositionData,
        setResponseDispositionFilteredData,
        setResponseDispositionFilteredDataApi,
        setFinishedResponseDispositionApi,
        setAssociationList,
        setStateList,
        setGeographyByCounty,
        setGeographyByCountyFilteredData,
        setGeographyByCountyFilteredDataApi,
        setFinishedGeographyByCountyApi,
        setGeographyByGrowerDistrict,
        setGeographyByGrowerDistrictFilteredData,
        setGeographyByGrowerDistrictFilteredDataApi,
        setFinishedGeographyByGrowerDistrictApi,
        setLegislatorsBranchList,
        setLegislatorsDistrictList,
        setLegislatorsLevelList,
        setLegislatorsStateList,
        setLegislatorsData,
        setLegislatorsFilteredData,
        setLegislatorsFilteredDataApi,
        setFinishedLegislatorsApi,
        setFinishedExportApi,
        setCustomerTableFilters,
        setCornDemographicsDataApi,
        setSoybeanDemographicsDataApi,
        setCattleDemographicsDataApi,
        setHogsDemographicsDataApi,
        setAgeDemographicsDataApi,
        setCampaignDataApi,
        setCampaignResponseDataApi,
        setTagsAnyDataApi,
        setTagsAllDataApi,
        setResponseDispositionDataApi,
        setGeographyByCountyDataApi,
        setGeographyByGrowerDistrictDataApi,
        setLegislatorsDataApi,
        setFinishedDasboardDataLoad,
    }}>
        {children}
    </GlobalContext.Provider>);
}
