import React, { useContext, useState, useEffect } from 'react';
import { XAxis, Tooltip, CartesianGrid, Bar, BarChart, Legend, YAxis, Pie, PieChart, Cell, Brush } from 'recharts';
import { Container, Row, Col } from 'react-grid-system';

import { groupByCount } from '../../../utility/utility';

import { GlobalContext } from '../../../context/GlobalState';
import Popup from 'reactjs-popup';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';

export default function EntryChartComponent() {

    const { removeFilter,removeNewFilter,entryFilteredValues, setEntryFilteredData, setHarvestFilteredData, harvestFilteredValues, filters, setEntryIds, addFilter, addNewFilter, loggedinUser, entryValues } = useContext(GlobalContext);
    const [barChartData, setBarChartData] = useState([])
    const [piChartData, setPiChartData] = useState([])
    const [entryCounts, setEntryCounts] = useState(0)
    const [filterCounts, setFilterCounts] = useState(0)

    useEffect(() => {
        if (entryFilteredValues.length !== entryCounts || filterCounts !== filters.length) {
            setPiChartData(groupByCount(entryFilteredValues, 'EntryStatus'))
            if (loggedinUser.UserRole === "Seed Representative") {
                setBarChartData(groupByCount(entryFilteredValues, 'Class'))
            }
            if (loggedinUser.UserRole === "National Admin" || loggedinUser.UserRole === "StateAdmin") {
                setBarChartData(groupByCount(entryFilteredValues, 'Voucher'))
            }
            setEntryCounts(entryFilteredValues.length)
            setFilterCounts(filters.length)
        }
    })

    const filterChart = (name, key) => {
        if(name!="brush"){
            const newFilteredData = entryFilteredValues.filter((data) => { return data[key] === name })
            var filterIds = newFilteredData.map((el) => { return el.EntryContestID; });
            setEntryIds(filterIds);
            setEntryFilteredData(newFilteredData)
            setHarvestFilteredData(harvestFilteredValues)
            filters.map((filter) => {
                if (filter.filter == 'entry' && filter.key == key) {
                    removeFilter(filter.key);
                    removeNewFilter(filter.key);                    
                }
            });
            addFilter({ key: key, value: name, filter: 'entry' });
            addNewFilter({ key: key, value: name, filter: 'entry' });
            setFilterCounts(filterCounts + 1);
            // const hasSelectedFilter = filters.filter((data) => {
            //     return data.key === key && data.value === name
            // })
            // if (hasSelectedFilter.length === 0) {
            //     addFilter({ key: key, value: name, filter: 'entry' });
            //     addNewFilter({ key: key, value: name, filter: 'entry' });
            //     setFilterCounts(filterCounts + 1)
            // }
        }        
    }

    const COLORS = {
        "Complete": "rgb(70, 198, 70)",
        "Purged": "rgb(249, 63, 23)",
        "Started": "rgb(68, 119, 170)",
        "Completed": "rgb(249, 63, 23)",
    }

    const colorsBarChart = ["#4477aa"];
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip">
                    <p className="bold">{`${payload[0].name}`}</p>
                    <p className="label">Harvest Status : {`${payload[0].name}`}</p>
                    <p className="desc">Count : {`${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };
    const entryStatus = {
        text: 'Entry Status', dataField: 'EntryStatus'
    };
    const voucher = {
        text: 'Voucher', dataField: 'Voucher'
    };
    function getOptions(field) {
        let Options = entryValues.map((el) => { return el[field]; }).filter((value, index, self) => self.indexOf(value) === index);
        // let OptionsJson=Options.map((el)=>{return {id:"Address1",value:el,label:el}});
        let OptionsJson = Options.map((el) => { var obj = {}; obj[field] = el; return obj; });
        return OptionsJson;
    }

    return (
        <div>
            <Row className="boxPadding">
                <Col sm={1}>
                    <h5>Filter</h5>
                </Col>
                <Col sm={5}>
                    <Popup
                        trigger={<button className="form-control" style={{ width: '180px', 'margin-bottom': '10px', 'disabled': 'true' }}>Entry Status</button>}
                        position="bottom left"
                        nested
                        style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                    >

                        {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"entry"} close={close} column={entryStatus} columnOptionParent={entryValues} /> </div>}

                    </Popup>
                </Col>
                <Col sm={1}>
                    <h5>Filter</h5>
                </Col>
                <Col sm={5}>
                <Popup
                        trigger={<button className="form-control" style={{ width: '180px', 'margin-bottom': '10px', 'disabled': 'true' }}>Voucher</button>}
                        position="bottom left"
                        nested
                        style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                    >

                        {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"entry"} close={close} column={voucher} columnOptionParent={entryValues} /> </div>}

                    </Popup>
                </Col>
                <Col sm={4}>
                    <h4 className="textalignleft">Entry Chart</h4>
                    <div style={{ marginTop: 20, textAlign:'left' }}>
                        <PieChart width={500} height={250}>
                            <Pie data={piChartData} onClick={(e) => { filterChart(e.name, 'EntryStatus') }} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} fill="#8884d8">
                                {piChartData.map((entry, index) => <Cell fill={COLORS[entry.name]}/>)}
                            </Pie>
                            <Legend layout="vertical" verticalAlign="middle" align="right"
                                payload={
                                    piChartData.map(
                                        (item, index1) => ({
                                            id: item.name,
                                            type: "square",
                                            value: `${item.name} (${item.value})`,
                                            color: COLORS[item.name]
                                        })
                                    )
                                }
                            />
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                    </div>
                </Col>
                {loggedinUser.UserRole === "Seed Representative" &&
                    <Col sm={8}>
                        <h4 className="textalignleft">Class Chart</h4>
                        <BarChart width={850} height={400} data={barChartData} onClick={(e) => { filterChart(e==null?"brush":e.activeLabel, 'Class') }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" textAnchor="end" tick={{ angle: -15 }} height={75} style={{height:'200px',fontSize:'14px'}} />
                            <YAxis />
                            <Tooltip />
                            <Brush dataKey='name' height={30} stroke="#4477aa" startIndex={0} endIndex={8} travellerWidth={0} />
                            <Bar dataKey="value" name="Voucher">
                                {barChartData.map((entry, index) => <Cell fill={colorsBarChart[index % colorsBarChart.length]} />)}
                            </Bar>
                        </BarChart>
                    </Col>}
                {(loggedinUser.UserRole === "National Admin"||loggedinUser.UserRole === "StateAdmin") &&
                    <Col sm={8}>
                        <h4 className="textalignleft">Voucher Chart</h4>
                        <BarChart width={850} height={400} data={barChartData} onClick={(e) => { filterChart(e==null?"brush":e.activeLabel, 'Voucher') }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" textAnchor="end" tick={{ angle: -15 }} interval={0} height={75} style={{height:'200px',fontSize:'14px'}} />
                            <YAxis />
                            <Tooltip />
                            <Brush dataKey='name' height={30} stroke="#4477aa" startIndex={0} endIndex={8} travellerWidth={0} />
                            <Bar dataKey="value" name="Voucher">
                                {barChartData.map((entry, index) => <Cell fill={colorsBarChart[index % colorsBarChart.length]} />)}
                            </Bar>
                        </BarChart>
                    </Col>}
            </Row>
        </div>
    )
}