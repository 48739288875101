import React, { useContext } from 'react';
import Loader from 'react-loader-spinner';
import { GlobalContext } from '../../../context/GlobalState';

export default function LoaderComponent() {
    const styleLoader = { position: "fixed", top: "30%", left: "50%", transform: "translate(-50%, -50%)", "z-index":"100" };
    return (
        <div style={styleLoader}>
            <Loader
                type="Oval"
                color="#3a8104"
                height={100}
                width={100}              
                visible={true}

            />
        </div>
    );
}