import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import Popup from 'reactjs-popup';


import { Container, Row, Col } from 'react-grid-system';


import { GlobalContext } from '../../../context/GlobalState';

export default function HarvestTableComponent () {
    
    const {  entryFilteredValues, setEntryFilteredData, setHarvestFilteredData, harvestFilteredValues, filters, setEntryIds, addFilter, removeFilter, harvestValues  } = useContext(GlobalContext);
    function headerContent(column, colIndex, onFilter) {
        return (
            <Popup
                trigger={<div style={{ whiteSpace: 'nowrap', display:'inline',width:'100%',float:'left','min-width':'200px'}}><span style={{float:'left'}}>{column.text}</span><i style={{ marginLeft:'10px'}} className="fa fa-search"></i></div>}
                position="top left"
                nested
                style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
            >

                {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                    <ColumnCustomFilter  table={"harvest"} close={close} column={column} columnOptionParent={harvestValues} /> </div>}

            </Popup>

        );
    }


    const [harvestCounts, setHarvestCounts] = useState(
        {
            "total": 0,
            "started": 0,
            "purged": 0,
            "initial_check": 0,
            "initial_check_recheck": 0,
            "initial_check_recheck_approved": 0,
            "initial_check_approved": 0,
            "completed_initial_check": 0,
            "re_check": 0
        }
    )

    useEffect(() => {
        if (harvestFilteredValues.length !== harvestCounts.total) {
                const recheckValue = harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"].includes("Recheck")}).length
                setHarvestCounts({
                    "total": harvestFilteredValues.length,
                    "started": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Started"}).length,
                    "purged": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Purged"}).length,
                    "initial_check": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Completed: Initial Check"}).length,
                    "initial_check_recheck": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Completed: Initial Check , Recheck"}).length,
                    "initial_check_recheck_approved": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Completed: Initial Check , Recheck Approved"}).length,
                    "initial_check_approved": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Completed: Initial Check Approved"}).length,
                    "completed_initial_check": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"].includes("Completed: Initial Check")}).length - recheckValue,
                    "re_check": recheckValue
                })
        }
    })

    let entryContestId;

    const columns = [
        {
            text: 'Entry Contest ID', dataField: 'EntryContestID', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Address 1', dataField: 'HC_Address1', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Business', dataField: 'HC_Business', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'City', dataField: 'HC_City', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Class', dataField: 'HC_Class', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Complete Date', dataField: 'HC_CompleteDate', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Country', dataField: 'HC_County', sortable: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Display Name', dataField: 'HC_DisplayName', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Email Address', dataField: 'HC_EmailAddress', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Entry Code', dataField: 'HC_EntryCode', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Field State', dataField: 'HC_FieldState', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'First Name', dataField: 'HC_FirstName', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Harvest Date', dataField: 'HC_HarvestDate', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Harvest Status', dataField: 'HC_HarvestStatus', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Home', dataField: 'HC_Home', sortable: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Hybrid brand', dataField: 'HC_HybridBrand', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Hybrid Number', dataField: 'HC_HybridNumber', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Last Name', dataField: 'HC_LastName', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Mobile', dataField: 'HC_Mobile', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Postal Code', dataField: 'HC_PostalCode', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'State', dataField: 'HC_STATE', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Sales Rep', dataField: 'HC_SalesRep', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Sales Rep Id', dataField: 'HC_SalesRepID', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Suffix', dataField: 'HC_Suffix', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Trait', dataField: 'HC_Trait', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Voucher', dataField: 'HC_Voucher', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Work', dataField: 'HC_Work', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Harvest Contest ID', dataField: 'HarvestContestID', sort: true,
            headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
    ];
    const options = {
        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
            { text: 'All', value: harvestFilteredValues.length },
        ],
        sizePerPage: 5,
        pageStartIndex: 0,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
    }
    function afterFilter(newResult, newFilter) {
        filters.map((filter) => {
            if (filter.type === 'table' && filter.filter === 'harvest') {
                removeFilter(filter)
            }
        })
        Object.keys(newFilter).map((filter) => {
            addFilter({ key: filter, value: newFilter[filter].filterVal, filter: 'harvest', type: 'table' });
        })
        if (filters.length === 0) {
            setEntryIds([]);
        } else {
            var filterIds = newResult.map((el) => { return el.EntryContestID; });
            setEntryIds(filterIds);
        }
        setHarvestFilteredData(newResult)
        setEntryFilteredData(entryFilteredValues)
        if (entryContestId) {
            entryContestId('');
        }
    }
    return (
        <div>
            <Row className="boxPadding">
                    <Col sm={4}>
                        <div>
                            <h4 className="textalignleft">
                                Harvest Status
                            </h4>
                            <table>
                                <tr>
                                    <th>Harvest Status</th>
                                    <th>Counts</th>
                                </tr>
                                <tr>
                                    <td>Completed: Initial Check</td>
                                    <td>{harvestCounts.initial_check}</td>
                                </tr>
                                <tr>
                                    <td>Completed: Initial Check , Recheck Approved</td>
                                    <td>{harvestCounts.initial_check_recheck_approved}</td>
                                </tr>
                                <tr>
                                    <td>Completed: Initial Check  Approved</td>
                                    <td>{harvestCounts.initial_check_approved}</td>
                                </tr>
                                <tr>
                                    <td>Completed: Initial Check , Recheck</td>
                                    <td>{harvestCounts.initial_check_recheck}</td>
                                </tr>
                                <tr>
                                    <td>Started</td>
                                    <td>{harvestCounts.started}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col sm={8}>
                    <div id="tablediv" style={{paddingTop:'35px'}}>
                        {/* <DataTable
                            pagination={true}
                            paginationPerPage={10}
                            title="Harvest Details"
                            columns={columns}
                            data={harvestFilteredValues}
                        /> */}
                        <BootstrapTable
                            scrollX
                            keyField="name"
                            data={harvestFilteredValues}
                            columns={columns}
                            filter={filterFactory({ afterFilter })}
                            pagination={paginationFactory(options)}
                            noDataIndication="There is no data"
                            width="200"
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        />
                    </div>
                    </Col>
                </Row>
        </div>
    )
}