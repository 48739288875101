import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import BootstrapTable from 'react-bootstrap-table-next';
import Popup from 'reactjs-popup';
import { Pie, PieChart, Cell, Tooltip } from 'recharts'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import { GlobalContext } from '../../../context/GlobalState';
import { groupBy } from '../../../utility/utility';

export default function CampaignDataComponent() {
    const { addFilter, addNewFilter, filters, removeFilter, removeNewFilter, setEntryIds, campaignData, campaignFilteredData, setCampaignFilteredData, setCampaignFilteredDataApi, setFinishedCampaignDataApi,
        campaignResponseData, setCampaignResponseFilteredDataApi, setCampaignResponseFilteredData, setFinishedCampaignResponseApi,
        tagsAnyData, setTagsAnyFilteredDataApi, setTagsAnyFilteredData, setFinishedTagsAnyApi,
        tagsAllData, tagsAllFilteredData, setTagsAllFilteredDataApi, setTagsAllFilteredData, setFinishedTagsAllApi,
        responseDispositionData, responseDispositionFilteredData, setResponseDispositionFilteredDataApi, setResponseDispositionFilteredData, setFinishedResponseDispositionApi,
        } = useContext(GlobalContext);
    const [campaignCounts, setCampaignCounts] = useState(0);
    const [campaignTypeData, setCampaignTypeData] = useState([]);
    const [pieChartData, setPieChartData] = useState([]);
    const [colorsData, setColorsData] = useState([]);
    const [filterCounts, setFilterCounts] = useState(0)
    function headerContent(column, colIndex, onFilter) {
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="bottom middle"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"campaignData"} close={close} column={column} columnOptionParent={campaignData} />
                    </div>}

                </Popup>
            </div>
        );
    }
    const columns = [
        {
            text: 'Campaign Type', dataField: 'campaigntype', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }, footer: 'Total'
        },
        {
            text: 'Total', dataField: 'campaigntypetotal', sort: true, formatter: (col, row)=>{return col.toLocaleString()},footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        }
    ];
    const colorsTop5 = ["rgb(19,129,133)", "rgb(38,160,167)", "rgb(101,211,218)", "rgb(121,214,159)", "rgb(203,233,137)"];
    const labelColorsTop5 = ["white", "white", "white", "black", "black"];
    const defaultSorted = [{
        dataField: 'campaigntype', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];
    const DataFormater = (number) => {
        if(number > 1000000000){
          return (number/1000000000).toString() + 'B';
        }else if(number > 1000000){
          return (number/1000000).toString() + 'M';
        }else if(number > 1000){
          return (number/1000).toString() + 'K';
        }else{
          return number.toString();
        }
      }
    useEffect(() => {
        let localFilters = filters;
        if (localFilters.length != 0) {
            const filtersGroupedByKey = groupBy(localFilters, 'key');
            const filtersGroupByTable = groupBy(localFilters, 'filter');
            var newFilter = {};
            Object.keys(filtersGroupByTable).map((filterKey) => {
                let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                var newFilterByKey = {};
                Object.keys(filterGroupByKey).map((filKey) => {
                    let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                    newFilterByKey[filKey] = filterValues.toString();
                });
                newFilter[filterKey] = newFilterByKey;
            });
            setFinishedCampaignDataApi(false);
            setCampaignFilteredDataApi(newFilter);
        } else {
            if (campaignData.length != 0) {
                setCampaignFilteredData(campaignData);
            } else {
                setFinishedCampaignDataApi(false);
                setCampaignFilteredDataApi([]);
            }
        }
    }, []);
    useEffect(() => {
        let campaignDataCount = campaignFilteredData.reduce((sum, item) => sum += item.campaigntypetotal, 0);
        if (campaignDataCount != campaignCounts) {
            let campaignTypeTop5 = campaignFilteredData.sort((a, b) => b.campaigntypetotal - a.campaigntypetotal).slice(0, 5);
            let objTop5 = [];
            objTop5 = campaignTypeTop5.map((val) => {
                let campaign = { name: val.campaigntype, value: val.campaigntypetotal, percentage: 0 };
                return campaign;
            });
            setCampaignCounts(campaignDataCount);
            setCampaignTypeData(campaignFilteredData);
            setPieChartData(objTop5);
        }
    }, [campaignFilteredData]);
    const filterChart = (name, key) => {
        if (name != "brush") {           
            let localFilter = filters;
            filters.map((filter) => {
                if (filter.filter == 'campaignData' && filter.key == key) {
                    removeFilter(filter.key);
                    removeNewFilter(filter.key);
                    localFilter = localFilter.filter(item => (item.key !== filter.key));
                }
            });
            addFilter({ key: key, value: name, filter: 'campaignData' });
            addNewFilter({ key: key, value: name, filter: 'campaignData' });
            localFilter.push({ key: key, value: name, filter: 'campaignData' });
            setFilterCounts(filterCounts + 1);
            if (localFilter.length != 0) {              
                const filtersGroupedByKey = groupBy(localFilter, 'key');
                const filtersGroupByTable = groupBy(localFilter, 'filter');
                var newFilter = {};
                Object.keys(filtersGroupByTable).map((filterKey) => {
                    let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                    var newFilterByKey = {};
                    Object.keys(filterGroupByKey).map((filKey) => {
                        let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                        newFilterByKey[filKey] = filterValues.toString();
                    });
                    newFilter[filterKey] = newFilterByKey;
                });                              
                setFinishedCampaignDataApi(false);
                setFinishedCampaignResponseApi(false);
                setFinishedTagsAnyApi(false);
                setFinishedTagsAllApi(false);
                setFinishedResponseDispositionApi(false);               
                setCampaignFilteredDataApi(newFilter);
                setCampaignResponseFilteredDataApi(newFilter);
                setTagsAnyFilteredDataApi(newFilter);
                setTagsAllFilteredDataApi(newFilter);
                setResponseDispositionFilteredDataApi(newFilter);              
            } else {              
                setCampaignFilteredData(campaignData);
                setCampaignResponseFilteredData(campaignResponseData);
                setTagsAnyFilteredData(tagsAnyData);
                setTagsAllFilteredData(tagsAllData);
                setResponseDispositionFilteredData(responseDispositionData);               
            }
        }
    }
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            var share = newdata.filter((item) => { if (item.name == payload[0].name) return item.percentage; });
            return (
                <div className="custom-tooltip" style={{ fontSize: '12px' }}>
                    <p style={{ marginBottom: '2px' }} className="bold">{`${payload[0].name}`}</p>
                    <p style={{ marginBottom: '2px' }} className="label">Campaign Type : {`${payload[0].name}`}</p>
                    <p style={{ marginBottom: '2px' }} className="desc">Total : {`${DataFormater(payload[0].value)}`}</p>
                    <p style={{ marginBottom: '2px' }} className="desc">Share : {`${share[0].percentage}%`}</p>
                </div>
            );
        }

        return null;
    };
    const RADIAN = Math.PI / 180;
    var newdata;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
        console.log(percent);
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        newdata = pieChartData.map((v) => { if (v.name == name) { v.percentage = (percent * 100).toFixed(1); } return v; });
        if ((percent * 100).toFixed(1) > 5) {
            return (
                <text style={{ fontSize: '12px',fontWeight:'bold' }} x={x} y={y} fill={labelColorsTop5[index % labelColorsTop5.length]} textAnchor="middle" dominantBaseline="central">
                    {`${(name)} - `}
                    {`${(percent * 100).toFixed(1)}%`}
                </text>
            );
        }
    };
    return (
        <div style={{ width: '50%' }}>
            <Col sm={7} style={{ float: 'left' }} id="campaignData">
                <div id="tablediv" style={{ padding: '20px', paddingTop: "10px" }}>
                    <h5>Campaign</h5>
                    <BootstrapTable
                        scrollX
                        keyField="name"
                        data={campaignTypeData}
                        columns={columns}
                        noDataIndication="There is no data"
                        width="200"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        // defaultSorted={defaultSorted}
                    />
                </div>
            </Col>
            <Col sm={5} style={{ float: 'left' }}>
                <div style={{ padding: '20px', paddingTop: "10px" }}>
                    <h5 className="textalignleft">Top 5 Campaign Chart</h5>
                    <div style={{ marginTop: 20, textAlign: 'left' }}>
                        <PieChart width={250} height={250}>
                            <Pie stroke="none" data={pieChartData} isAnimationActive={false} labelLine={false}
                                label={renderCustomizedLabel} onClick={(e) => { filterChart(e.name, 'campaigntype') }} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={120} fill="#8884d8">
                                {pieChartData.map((entry, index) => <Cell fill={colorsTop5[index % colorsTop5.length]} />)}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                    </div>
                </div>
            </Col>
        </div>
    )
}