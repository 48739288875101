import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Popup from 'reactjs-popup';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import axios from "axios";

import { GlobalContext } from '../../../context/GlobalState';

export default function LegislatorsFiltersComponent() {
    const { baseUrl,loggedinUser, setLegislatorsStateList,setLegislatorsBranchList,setLegislatorsLevelList,setLegislatorsDistrictList,legislatorsStateList, legislatorsLevelList, legislatorsBranchList, legislatorsDistrictList, dashboardFilteredData } = useContext(GlobalContext);
    const [topCounts, setTopCounts] = useState(
        {
            "total": 0,
            "active": 0,
            "lapsed": 0,
            "prospects": 0,
            "participant": 0,
        }
    )
    useEffect(() => {
        if (legislatorsStateList.length == 0) {
            axios.post(baseUrl+"list_generator/legislator_state_list", loggedinUser.Client=="National Corn Growers Association" ? {} : { "topCounts": { "associationdescription": loggedinUser.Client } }).then(result => {
                setLegislatorsStateList(result.data.aaData);
            });
        }
        if (legislatorsBranchList.length == 0) {
            axios.post(baseUrl+"list_generator/legislator_branch_list", loggedinUser.Client=="National Corn Growers Association" ? {} : { "topCounts": { "associationdescription": loggedinUser.Client } }).then(result => {
                setLegislatorsBranchList(result.data.aaData);
            });
        }
        if (legislatorsLevelList.length == 0) {
            axios.post(baseUrl+"list_generator/legislator_level_list", loggedinUser.Client=="National Corn Growers Association" ? {} : { "topCounts": { "associationdescription": loggedinUser.Client } }).then(result => {
                setLegislatorsLevelList(result.data.aaData);
            });
        }
        if (legislatorsDistrictList.length == 0) {
            axios.post(baseUrl+"list_generator/legislator_district_list", loggedinUser.Client=="National Corn Growers Association" ? {} : { "topCounts": { "associationdescription": loggedinUser.Client }}).then(result => {
                setLegislatorsDistrictList(result.data.aaData);
            });
        }
    }, []);
    useEffect(() => {
        let membersCount = dashboardFilteredData.reduce((sum, item) => sum += item.active + item.lapsed + item.prospects + item.participant, 0);
        console.log(dashboardFilteredData);
        if (membersCount !== topCounts.total) {
            let active = dashboardFilteredData.reduce((sum, item) => sum += item.active, 0);
            let lapsed = dashboardFilteredData.reduce((sum, item) => sum += item.lapsed, 0);
            let prospects = dashboardFilteredData.reduce((sum, item) => sum += item.prospects, 0);
            let participant = dashboardFilteredData.reduce((sum, item) => sum += item.participant, 0);
            //let participant = dashboardFilteredData.filter((e) => {return e["ContactType_Name"] === "Participant"}).length;           
            //let total =dashboardFilteredData.length;
            let total = active + lapsed + prospects + participant;
            setTopCounts({
                "total": total,
                "active": active,
                "lapsed": lapsed,
                "prospects": prospects,
                "participant": participant
            });
        }
    }, [dashboardFilteredData]);
    const legStateList = {
        text: 'State', dataField: 'legislator_statename'
    };
    const legLevelList = {
        text: 'Level', dataField: 'legislator_level'
    };
    const legBranchList = {
        text: 'Branch', dataField: 'legislator_branch'
    };
    const legDistrictList = {
        text: 'District', dataField: 'legislator_district'
    };
    return (
        <div>
            <Row className="boxPadding" style={{ 'marginBottom': '10px', 'marginTop': '50px' }}>
                <Col sm={6}>
                    <h4 className="textalignleft" style={{ marginLeft: "15px" }}>Filters</h4>
                    <Col sm={3} style={{ marginBottom: '20px', float: "left" }}>
                        <Popup
                            trigger={<button className="form-control" style={{ width: '130px', 'margin-bottom': '10px', 'disabled': 'true' }}>Level</button>}
                            position="bottom left"
                            nested
                            style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                        >

                            {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"legislatorsData"} close={close} column={legLevelList} columnOptionParent={legislatorsLevelList} /> </div>}

                        </Popup>
                    </Col>
                    <Col sm={3} style={{ marginBottom: '20px', float: "left" }}>
                        <Popup
                            trigger={<button className="form-control" style={{ width: '130px', 'margin-bottom': '10px', 'disabled': 'true' }}>Branch</button>}
                            position="bottom left"
                            nested
                            style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                        >

                            {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"legislatorsData"} close={close} column={legBranchList} columnOptionParent={legislatorsBranchList} /> </div>}

                        </Popup>
                    </Col>
                    <Col sm={3} style={{ marginBottom: '20px', float: "left" }}>
                        <Popup
                            trigger={<button className="form-control" style={{ width: '130px', 'margin-bottom': '10px', 'disabled': 'true' }}>State</button>}
                            position="bottom left"
                            nested
                            style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                        >

                            {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"legislatorsData"} close={close} column={legStateList} columnOptionParent={legislatorsStateList} /> </div>}

                        </Popup>
                    </Col>
                    <Col sm={3} style={{ marginBottom: '20px', float: "left" }}>
                        <Popup
                            trigger={<button className="form-control" style={{ width: '130px', 'margin-bottom': '10px', 'disabled': 'true' }}>District</button>}
                            position="bottom left"
                            nested
                            style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                        >

                            {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}><ColumnCustomFilter table={"legislatorsData"} close={close} column={legDistrictList} columnOptionParent={legislatorsDistrictList} /> </div>}

                        </Popup>
                    </Col>
                </Col>
                <Col sm={6}>
                    <Col sm={3} style={{ float: 'left' }}>
                        <div className="label2">Active</div>
                        <div className="label2">Prospects</div>
                        <div className="label2">Total</div>
                    </Col>
                    <Col sm={3} style={{ float: 'left' }}>
                        <div className="labelValue1 colorLightBlue">{topCounts.active.toLocaleString()}</div>
                        <div className="labelValue1 colorRed">{topCounts.prospects.toLocaleString()}</div>
                        <div className="labelValue1 colorBlack">{topCounts.total.toLocaleString()}</div>
                    </Col>
                    <Col sm={3} style={{ float: 'left' }}>
                        <div className="label2">Lapsed</div>
                        <div className="label2">Participant</div>
                    </Col>
                    <Col sm={3} style={{ float: 'left' }}>
                        <div className="labelValue1 colorViolet">{topCounts.lapsed.toLocaleString()}</div>
                        <div className="labelValue1 colorYellow">{topCounts.participant.toLocaleString()}</div>
                    </Col>
                </Col>
            </Row>
        </div>
    )
}