import {React,useContext} from 'react';
import { GlobalContext } from '../../../context/GlobalState';
import ExportCustomerCountComponent from './ExportCustomerCount';
import ExportCustomerTableComponent from './ExportCustomerTable';
import ExportComponent from '../../common/ExportComponent/ExportComponent'
import './ExportCustomerListComponent.css'
import LoaderComponent from '../../common/LoaderComponent/LoaderComponent'

export default  function ExportCustomerListComponent () {
    const { finishedCustomerListApi, finishedExportApi } = useContext(GlobalContext);
    if(finishedCustomerListApi==false||finishedExportApi==false){
        document.body.style.opacity=.5;
      }else{
        document.body.style.opacity=1;
      }
    return (
        <div> 
            {(finishedCustomerListApi==false||finishedExportApi==false) && <LoaderComponent></LoaderComponent>} 
            <ExportComponent></ExportComponent> 
            <ExportCustomerCountComponent></ExportCustomerCountComponent> 
            <ExportCustomerTableComponent></ExportCustomerTableComponent>                   
        </div>
    )
}