import axios from "axios";
export default (state, action) => {
    let oldFilters = state.newFilters;
    switch (action.type) {
        case 'REMOVE_FILTER':
            return {
                ...state,
                filters: state.filters.filter(filter => (filter.key !== action.payload))
            };
        case 'REMOVE_TEMP_FILTER':
            let index = state.tempFilters.indexOf(action.payload);
            let tempfiltr = state.tempFilters;
            if (index > -1) {
                tempfiltr.splice(index, 1);
            }
            return {
                ...state,
                tempFilters: tempfiltr
            };
        case 'REMOVE_TEMP_FILTER_UNCHECKED':
            let indexun = state.tempFiltersUnchecked.indexOf(action.payload);
            let tempfiltu = state.tempFiltersUnchecked;
            if (indexun > -1) {
                tempfiltu.splice(indexun, 1);
            }
            return {
                ...state,
                tempFiltersUnchecked: tempfiltu
            };
        case 'REMOVE_NEW_FILTER':
            delete oldFilters[action.payload]
            return {
                ...state,
                newFilters: oldFilters
            };
        case 'ADD_FILTER':
            return {
                ...state,
                filters: [...state.filters, action.payload]
            };
        case 'ADD_TEMP_FILTER':
            let tempfilt = state.tempFilters;
            tempfilt.push(action.payload);
            return {
                ...state,
                tempFilters: tempfilt
            };
        case 'ADD_TEMP_FILTER_UNCHECKED':
            let tempfiltun = state.tempFiltersUnchecked;
            tempfiltun.push(action.payload);
            return {
                ...state,
                tempFiltersUnchecked: tempfiltun
            };
        case 'ADD_NEW_FILTER':
            let data = action.payload;
            if (Object.keys(oldFilters).includes(data.key)) {
                if (!oldFilters[data.key].includes(data.value)) {
                    oldFilters[data.key].push(data.value)
                }
            } else {
                oldFilters[data.key] = [data.value]
            }
            return {
                ...state,
                newFilters: oldFilters
            };
        case 'CLEAR_FILTERS':
            return {
                ...state,
                filters: [],
                newFilters: []
            };
        case 'CLEAR_TEMP_FILTERS':
            return {
                ...state,
                tempFilters: []
            };
        case 'CLEAR_TEMP_FILTERS_UNCHECKED':
            return {
                ...state,
                tempFiltersUnchecked: []
            };
        case 'SET_ENTRY_DATA':
            return {
                ...state,
                entryValues: action.payload
            };
        case 'SET_HARVEST_DATA':
            return {
                ...state,
                harvestValues: action.payload
            };
        case 'ADD_TEST_COUNT':
            return {
                ...state,
                count: action.payload
            };
        case 'SET_HARVEST_FILTERED_DATA':
            let harvestIdFilteredData = action.payload;
            if (state.filteredIds.length > 0) {
                harvestIdFilteredData = action.payload.filter((d) => {
                    return state.filteredIds.includes(d.EntryContestID);
                })
            }
            return {
                ...state,
                harvestFilteredValues: harvestIdFilteredData
            };
        case 'SET_ENTRY_FILTERED_DATA':
            let entryIdFilteredData = action.payload;
            if (state.filteredIds.length > 0) {
                entryIdFilteredData = action.payload.filter((d) => {
                    return state.filteredIds.includes(d.EntryContestID);
                })
            }
            return {
                ...state,
                entryFilteredValues: entryIdFilteredData
            };
        case 'SET_ENTRY_IDS':
            return {
                ...state,
                filteredIds: action.payload
            };
        case 'SET_SELECTED_TAB':
            return {
                ...state,
                selectedTabValue: action.payload
            };
        case 'SET_USER':
            return {
                ...state,
                loggedinUser: action.payload
            };
        case 'SET_DASHBOARD_DATA':
            return {
                ...state,
                dashboardData: action.payload
            };
        case 'SET_DASHBOARD_FILTERED_DATA':
            let dashboardIdFilteredData = action.payload;
            if (state.filteredIds.length > 0) {
                dashboardIdFilteredData = action.payload.filter((d) => {
                    return state.filteredIds.includes(d.MemberKey);
                })
            }
            return {
                ...state,
                dashboardFilteredData: dashboardIdFilteredData
            };
        case 'SET_CUSTOMER_LIST_DATA':
            return {
                ...state,
                customerListValues: action.payload
            };
        case 'SET_CUSTOMER_LIST_FILTERED_DATA':
            let customerListIdFilteredData = action.payload;
            if (state.filteredIds.length > 0) {
                customerListIdFilteredData = action.payload.filter((d) => {
                    return state.filteredIds.includes(d.MemberKey);
                })
            }
            return {
                ...state,
                customerListFilteredValues: customerListIdFilteredData
            };
        case 'SET_CORN_DEMOGRAPHICS_DATA':
            return {
                ...state,
                cornDemographicsData: action.payload
            };
        case 'SET_CORN_DEMOGRAPHICS_FILTERED_DATA':
            let cornDemographicsIdFilteredData = action.payload;
            if (state.filteredIds.length > 0) {
                cornDemographicsIdFilteredData = action.payload.filter((d) => {
                    return state.filteredIds.includes(d.MemberKey);
                })
            }
            return {
                ...state,
                cornDemographicsFilteredData: cornDemographicsIdFilteredData
            };
        case 'SET_SOYBEAN_DEMOGRAPHICS_DATA':
            return {
                ...state,
                soybeanDemographicsData: action.payload
            };
        case 'SET_SOYBEAN_DEMOGRAPHICS_FILTERED_DATA':
            return {
                ...state,
                soybeanDemographicsFilteredData: action.payload
            };
        case 'SET_CATTLE_DEMOGRAPHICS_DATA':
            return {
                ...state,
                cattleDemographicsData: action.payload
            };
        case 'SET_CATTLE_DEMOGRAPHICS_FILTERED_DATA':
            return {
                ...state,
                cattleDemographicsFilteredData: action.payload
            };
        case 'SET_HOGS_DEMOGRAPHICS_DATA':
            return {
                ...state,
                hogsDemographicsData: action.payload
            };
        case 'SET_HOGS_DEMOGRAPHICS_FILTERED_DATA':
            return {
                ...state,
                hogsDemographicsFilteredData: action.payload
            };
        case 'SET_AGE_DEMOGRAPHICS_DATA':
            return {
                ...state,
                ageDemographicsData: action.payload
            };
        case 'SET_AGE_DEMOGRAPHICS_FILTERED_DATA':
            return {
                ...state,
                ageDemographicsFilteredData: action.payload
            };
        case 'SET_EXPIREDATE_LIST':
            return {
                ...state,
                expireDateList: action.payload
            };
        case 'SET_TRANSACTIONDATE_LIST':
            return {
                ...state,
                transactionDateList: action.payload
            };
        case 'SET_STATUS_LIST':
            return {
                ...state,
                statusList: action.payload
            };
        case 'SET_LOADER':
            return {
                ...state,
                loader: action.payload
            };
        case 'SET_FINISH_DASHBOARD_API':
            return {
                ...state,
                finishedDashboardApi: action.payload
            };
        case 'SET_FINISH_CUSTOMER_LIST_API':
            return {
                ...state,
                finishedCustomerListApi: action.payload
            };
        case 'SET_FINISH_CORN_DEMO_API':
            return {
                ...state,
                finishedCornDemoApi: action.payload
            };
        case 'SET_FINISH_SOY_DEMO_API':
            return {
                ...state,
                finishedSoyDemoApi: action.payload
            };
        case 'SET_FINISH_CATTLE_DEMO_API':
            return {
                ...state,
                finishedCattleDemoApi: action.payload
            };
        case 'SET_FINISH_HOGS_DEMO_API':
            return {
                ...state,
                finishedHogsDemoApi: action.payload
            };
        case 'SET_FINISH_AGE_DEMO_API':
            return {
                ...state,
                finishedAgeDemoApi: action.payload
            };
        case 'SET_CAMPAIGN_DATA':
            return {
                ...state,
                campaignData: action.payload
            };
        case 'SET_CAMPAIGN_FILTERED_DATA':
            return {
                ...state,
                campaignFilteredData: action.payload
            };
        case 'SET_FINISH_CAMPAIGN_DATA_API':
            return {
                ...state,
                finishedCampaignDataApi: action.payload
            };
        case 'SET_CAMPAIGN_RESPONSE_DATA':
            return {
                ...state,
                campaignResponseData: action.payload
            };
        case 'SET_CAMPAIGN_RESPONSE_FILTERED_DATA':
            return {
                ...state,
                campaignResponseFilteredData: action.payload
            };
        case 'SET_FINISH_CAMPAIGN_RESPONSE_API':
            return {
                ...state,
                finishedCampaignResponseApi: action.payload
            };
        case 'SET_TAGS_ANY_DATA':
            return {
                ...state,
                tagsAnyData: action.payload
            };
        case 'SET_TAGS_ANY_FILTERED_DATA':
            return {
                ...state,
                tagsAnyFilteredData: action.payload
            };
        case 'SET_FINISH_TAGS_ANY_API':
            return {
                ...state,
                finishedTagsAnyApi: action.payload
            };
        case 'SET_TAGS_ALL_DATA':
            return {
                ...state,
                tagsAllData: action.payload
            };
        case 'SET_TAGS_ALL_FILTERED_DATA':
            return {
                ...state,
                tagsAllFilteredData: action.payload
            };
        case 'SET_FINISH_TAGS_ALL_API':
            return {
                ...state,
                finishedTagsAllApi: action.payload
            };
        case 'SET_RESPONSE_DISPOSITION_DATA':
            return {
                ...state,
                responseDispositionData: action.payload
            };
        case 'SET_RESPONSE_DISPOSITION_FILTERED_DATA':
            return {
                ...state,
                responseDispositionFilteredData: action.payload
            };
        case 'SET_FINISH_RESPONSE_DISPOSITION_API':
            return {
                ...state,
                finishedResponseDispositionApi: action.payload
            };
        case 'SET_ASSOCIATION_LIST':
            return {
                ...state,
                associationList: action.payload
            };
        case 'SET_STATE_LIST':
            return {
                ...state,
                stateList: action.payload
            };
        case 'SET_GEOGRAPHY_BY_COUNTY':
            return {
                ...state,
                geographyByCounty: action.payload
            };
        case 'SET_GEOGRAPHY_BY_COUNTY_FILTERED_DATA':
            return {
                ...state,
                geographyByCountyFilteredData: action.payload
            };
        case 'SET_FINISH_GEOGRAPHY_BY_COUNTY_API':
            return {
                ...state,
                finishedGeographyByCountyApi: action.payload
            };
        case 'SET_GEOGRAPHY_BY_GROWER_DISTRICT':
            return {
                ...state,
                geographyByGrowerDistrict: action.payload
            };
        case 'SET_GEOGRAPHY_BY_GROWER_DISTRICT_FILTERED_DATA':
            return {
                ...state,
                geographyByGrowerDistrictFilteredData: action.payload
            };
        case 'SET_FINISH_GEOGRAPHY_BY_GROWER_DISTRICT_API':
            return {
                ...state,
                finishedGeographyByGrowerDistrictApi: action.payload
            };
        case 'SET_LEGISLATORS_STATE_LIST':
            return {
                ...state,
                legislatorsStateList: action.payload
            };
        case 'SET_LEGISLATORS_LEVEL_LIST':
            return {
                ...state,
                legislatorsLevelList: action.payload
            };
        case 'SET_LEGISLATORS_BRANCH_LIST':
            return {
                ...state,
                legislatorsBranchList: action.payload
            };
        case 'SET_LEGISLATORS_DISTRICT_LIST':
            return {
                ...state,
                legislatorsDistrictList: action.payload
            };
        case 'SET_LEGISLATORS_DATA':
            return {
                ...state,
                legislatorsData: action.payload
            };
        case 'SET_LEGISLATORS_FILTERED_DATA':
            return {
                ...state,
                legislatorsFilteredData: action.payload
            };
        case 'SET_FINISH_LEGISLATORS_API':
            return {
                ...state,
                finishedLegislatorsApi: action.payload
            };
        case 'SET_FINISH_EXPORT_API':
            return {
                ...state,
                finishedExportApi: action.payload
            };
        case 'SET_CUSTOMER_TABLE_FILTERS':
            return {
                ...state,
                customerTableFilters: action.payload
            };
        case 'SET_FINISH_DASHBOARD_DATA_LOAD':
            return {
                ...state,
                finishedDasboardDataLoad: action.payload
            };


        default: return state;
    }
}