import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import BootstrapTable from 'react-bootstrap-table-next';
import Popup from 'reactjs-popup';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import { GlobalContext } from '../../../context/GlobalState';
import { groupBy } from '../../../utility/utility';
export default function SoybeanDemographicsComponent() {
    const { finishedSoyDemoApi, setFinishedSoyDemoApi, setSoybeanDemographicsFilteredDataApi, filters, setEntryIds, soybeanDemographicsData, soybeanDemographicsFilteredData, setSoybeanDemographicsFilteredData } = useContext(GlobalContext);
    const [soybeanCounts, setSoybeanCounts] = useState(0);
    const [soybeanData, setSoybeanData] = useState([]);
    const [soybeanDataWithoutFilt, setSoybeanDataWithoutFilt] = useState([]);
    function headerContent(column, colIndex, onFilter) {
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="bottom middle"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"soybeanDemographics"} close={close} column={column} columnOptionParent={soybeanDemographicsData} />
                    </div>}

                </Popup>
            </div>
        );
    }
    const columns = [
        {
            text: 'Demo Type', dataField: 'soybeansrange', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>Soybeans - {col}</span> }, footer: 'Total'
        },
        {
            text: 'Total', dataField: 'soybeansrangetotal', sort: true,formatter: (col, row)=>{return col.toLocaleString()}, footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        }
    ];
    const defaultSorted = [{
        dataField: 'soybeansrange', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];
    useEffect(() => {
        let localFilters = filters;
        if (localFilters.length != 0) {
            const filtersGroupedByKey = groupBy(localFilters, 'key');
            const filtersGroupByTable = groupBy(localFilters, 'filter');
            var newFilter = {};
            Object.keys(filtersGroupByTable).map((filterKey) => {
                let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                var newFilterByKey = {};
                Object.keys(filterGroupByKey).map((filKey) => {
                    let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                    newFilterByKey[filKey] = filterValues.toString();
                });
                newFilter[filterKey] = newFilterByKey;
            });
            setFinishedSoyDemoApi(false);
            setSoybeanDemographicsFilteredDataApi(newFilter);
        } else {
            if (soybeanDemographicsData.length != 0) {
                setSoybeanDemographicsFilteredData(soybeanDemographicsData);
            } else {
                setFinishedSoyDemoApi(false);
                setSoybeanDemographicsFilteredDataApi([]);
            }
        }

    }, []);
    useEffect(() => {
        let soyDemoCount = soybeanDemographicsFilteredData.reduce((sum, item) => sum += item.soybeansrangetotal, 0);
        if (soyDemoCount != soybeanCounts) {
            setSoybeanCounts(soyDemoCount);
            setSoybeanData(soybeanDemographicsFilteredData);
        }
    }, [soybeanDemographicsFilteredData]);
    return (
        <Col sm={4}>
            <div id="tablediv" style={{ padding: '20px', paddingTop: "10px" }}>
                <h5>Soybeans</h5>
                <BootstrapTable
                    scrollX
                    keyField="name"
                    data={soybeanData}
                    columns={columns}
                    noDataIndication="There is no data"
                    width="200"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    // defaultSorted={defaultSorted}
                />
            </div>
        </Col>)
}