import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { GlobalContext } from '../../../context/GlobalState';
import EntryTableComponent from './EntryTable';
import EntryCountComponent from './EntryCount';
import EntryChartComponent from './EntryCharts';
import ExportComponent from '../../common/ExportComponent/ExportComponent'

import './EntryComponent.css';

export default  function EntryComponent () {
    const { filters, addFilter,setTabData } = useContext(GlobalContext);
    setTabData(0);
    return (
        <div>
            <ExportComponent></ExportComponent> 
            <EntryCountComponent></EntryCountComponent>
            <EntryChartComponent></EntryChartComponent>
            <EntryTableComponent></EntryTableComponent>
        </div>
    )
}