import React, { useContext, useEffect } from 'react';

import HarvestTableComponent from './HarvestTable';
import HarvestCountComponent from './HarvestCount';
import HarvestChartComponent from './HarvestCharts';
import ExportComponent from '../../common/ExportComponent/ExportComponent'
import { GlobalContext } from '../../../context/GlobalState';


export default function HarvestComponent () {
    const { setTabData } = useContext(GlobalContext);
    setTabData(1);
    return (
        <div>
            <ExportComponent></ExportComponent>            
            <HarvestCountComponent></HarvestCountComponent>
            <HarvestChartComponent></HarvestChartComponent>
            <HarvestTableComponent></HarvestTableComponent>
        </div>
    )
}