export const groupByCount = (array, key) =>{
    const groupedData = array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result
    }, {});
    let finalData = [];
    for (let data of Object.keys(groupedData)) {
        finalData.push({
            "name": data,
            "value": groupedData[data].length})
    }
    finalData.sort(function(a, b){return b.value - a.value});
    return finalData;
};
 // Accepts the array and key
export const groupBy = (array, key) => {
  // Return the end result
    return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
}; 
