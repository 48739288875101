import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';


import { GlobalContext } from '../../../context/GlobalState';

export default function EntryCountComponent () {
    
    const { entryFilteredValues } = useContext(GlobalContext);

    const [entryCounts, setEntryCounts] = useState(
        {
            "total": 0,
            "started": 0,
            "purged": 0,
            "complete": 0,
            "completed": 0,
        }
    )

    useEffect(() => {
        if (entryFilteredValues.length !== entryCounts.total) {
                setEntryCounts({
                    "total": entryFilteredValues.length,
                    "started": entryFilteredValues.filter((e) => {return e["EntryStatus"] === "Started"}).length,
                    "purged": entryFilteredValues.filter((e) => {return e["EntryStatus"] === "Purged"}).length,
                    "complete": entryFilteredValues.filter((e) => {return e["EntryStatus"] === "Complete"}).length,
                    "completed": entryFilteredValues.filter((e) => {return e["EntryStatus"] === "Completed"}).length,
                })
        }
    })

    return (
        <div>
            <Row style={{'margin-bottom':'10px'}}>
                <Col sm={1}>
                    {/* <div className="label1">Filter</div>  */}
                </Col>
                <Col sm={3}>
                    {/* <select></select> */}
                </Col>
                <Col sm={2}>
                    <div className="label1">TOTAL</div>
                    <div className="labelValue colorViolet">{entryCounts.total}</div>
                </Col>
                <Col sm={2}>
                    <div className="label1">STARTED</div>
                    <div className="labelValue colorBlue">{entryCounts.started}</div></Col>
                <Col sm={2}>
                    <div className="label1">COMPLETE</div>
                    <div className="labelValue colorGreen">{entryCounts.complete}</div></Col>
                <Col sm={2}>
                    <div className="label1">PURGED</div>
                    <div className="labelValue colorRed">{entryCounts.purged}</div></Col>
            </Row>
        </div>
    )
}