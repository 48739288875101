import React, { useContext, useState, useEffect } from 'react';

import { Container, Row, Col } from 'react-grid-system';


import { GlobalContext } from '../../../context/GlobalState';

export default function HarvestCountComponent () {
    
    const { harvestFilteredValues } = useContext(GlobalContext);

    const [harvestCounts, setHarvestCounts] = useState(
        {
            "total": 0,
            "started": 0,
            "purged": 0,
            "initial_check": 0,
            "initial_check_recheck": 0,
            "initial_check_recheck_approved": 0,
            "initial_check_approved": 0,
            "completed_initial_check": 0,
            "re_check": 0
        }
    )

    useEffect(() => {
        if (harvestFilteredValues.length !== harvestCounts.total) {
                const recheckValue = harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"].includes("Recheck")}).length
                setHarvestCounts({
                    "total": harvestFilteredValues.length,
                    "started": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Started"}).length,
                    "purged": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Purged"}).length,
                    "initial_check": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Completed: Initial Check"}).length,
                    "initial_check_recheck": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Completed: Initial Check , Recheck"}).length,
                    "initial_check_recheck_approved": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Completed: Initial Check , Recheck Approved"}).length,
                    "initial_check_approved": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"] === "Completed: Initial Check Approved"}).length,
                    "completed_initial_check": harvestFilteredValues.filter((e) => {return e["HC_HarvestStatus"].includes("Completed: Initial Check")}).length - recheckValue,
                    "re_check": recheckValue
                })
        }
    })

    return (
        <div>
            <Row>
                {/* <FilterHeader filters={this.state.filters} /> */}
                <Col sm={2}>
                    {/* <div className="label1">Filter</div>  */}
                </Col>
                <Col sm={2}>
                    <div className="label1">TOTAL</div>
                    <div className="labelValue colorViolet">{harvestCounts.total}</div>
                </Col>
                <Col sm={2}>
                    <div className="label1">STARTED</div>
                    <div className="labelValue colorBlue">{harvestCounts.started}</div></Col>
                <Col sm={2}>
                    <div className="label1">COMPLETED : INITIAL CHECK</div>
                    <div className="labelValue colorBlue">{harvestCounts.completed_initial_check}</div>
                </Col>
                <Col sm={2}>
                    <div className="label1">COMPLETE : RECHECK</div>
                    <div className="labelValue colorGreen">{harvestCounts.re_check}</div></Col>
                <Col sm={2}>
                    <div className="label1">PURGED</div>
                    <div className="labelValue colorRed">{harvestCounts.purged}</div></Col>
            </Row>
        </div>
    )
}