import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Popup from 'reactjs-popup';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { GlobalContext } from '../../../context/GlobalState';
import axios from "axios";
import { groupBy } from '../../../utility/utility';

export default function ExportCustomerTableComponent() {
    const { baseUrl,setFinishedDashboardApi, setDashboardFilteredDataApi,setCustomerListFilteredDataApi,setDashboardFilteredData,dashboardData, loggedinUser, dashboardFilteredData, customerListValues, customerListFilteredValues, setCustomerListFilteredData, setFinishedCustomerListApi, filters, setEntryIds, addFilter, addNewFilter, customerTableFilters } = useContext(GlobalContext);
    const [options, setOptions] = useState({
        'sizePerPage': 15
    });
    const [topCounts, setTopCounts] = useState(0);
    useEffect(() => {
        let localFilters = filters;
        if (localFilters.length != 0) {
            const filtersGroupedByKey = groupBy(localFilters, 'key');
            const filtersGroupByTable = groupBy(localFilters, 'filter');
            var newFilter = {};
            Object.keys(filtersGroupByTable).map((filterKey) => {
                let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                var newFilterByKey = {};
                Object.keys(filterGroupByKey).map((filKey) => {
                    let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                    newFilterByKey[filKey] = filterValues.toString();
                });
                newFilter[filterKey] = newFilterByKey;
            });
            setFinishedDashboardApi(false);
            setDashboardFilteredDataApi(newFilter);
            setFinishedCustomerListApi(false);
            setCustomerListFilteredDataApi(newFilter);
        } else {
            if (customerListValues.length != 0) {
                setCustomerListFilteredData(customerListValues);
            } else {
                setFinishedCustomerListApi(false);
                setCustomerListFilteredDataApi([]);
            }
            setDashboardFilteredData(dashboardData);
        }
    }, []);
    useEffect(() => {
        let membersCount = dashboardFilteredData.reduce((sum, item) => sum += item.active + item.lapsed + item.prospects + item.participant, 0);
        if (membersCount != topCounts) {
            setOptions({ sizePerPage: 15, totalSize: membersCount });
            setTopCounts(membersCount);
        }
    });
    function headerContent(column, colIndex, onFilter) {
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="bottom middle"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"customerList"} close={close} column={column} columnOptionParent={customerTableFilters[column.dataField]} />
                    </div>}

                </Popup>
            </div>
        );
    }
    const columns = [
        {
            text: 'Association', dataField: 'associationdescription', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Source', dataField: 'contacttype_name', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'ID#', dataField: 'existing_id', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'FJ', dataField: 'fjid', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'NCGAID', dataField: 'ncgaid', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Expire Date', dataField: 'expiredate', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'First Name', dataField: 'first_name', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Last Name', dataField: 'last_name', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Suffix', dataField: 'suffix', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Business', dataField: 'business', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Address 1', dataField: 'address_1', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Address 2', dataField: 'address_2', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'City', dataField: 'city', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'State', dataField: 'statecode', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Postal Code', dataField: 'zip', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'County', dataField: 'county', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Grower District', dataField: 'growerdistrict', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Email Address', dataField: 'email', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Phone', dataField: 'phone', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'AGE', dataField: 'age', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'MARKET ADVICE', dataField: 'market_advice', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'GENERATED ACRES', dataField: 'generated_acres', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'CORN', dataField: 'corn', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'ALFALFA', dataField: 'alfalfa', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'COTTON', dataField: 'cotton', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'BARLEY', dataField: 'barley', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'RICE', dataField: 'rice', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'TOBACCO', dataField: 'tobacco', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'SOYBEANS', dataField: 'soybeans', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'New Technologies', dataField: 'new_technologies', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'BEETS', dataField: 'beets', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'WHEAT', dataField: 'wheat', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'SORGHUM', dataField: 'sorghum', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'DAIRY (MILKING)', dataField: 'dairy', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Primary Tractor Brand', dataField: 'primary_tractor_brand', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'HOGS', dataField: 'hogs', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'SOWS', dataField: 'sows', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'FED BEEF', dataField: 'fed_beef', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'CALVES_BACKGROUND', dataField: 'calves_background', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'CALVES', dataField: 'calves', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'RIDGE TILLAGE', dataField: 'ridge_tillage', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'NO TILLAGE', dataField: 'no_tillage', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'MINIMAL TILLAGE', dataField: 'minimal_tillage', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'CONVENTIONAL TILLAGE', dataField: 'conventional_tillage', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'GPS', dataField: 'gps', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'DTN', dataField: 'dtn', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'FUTURES AND OPTIONS', dataField: 'futures_and_options', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Dekalb (acres)', dataField: 'dekalb', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Garst (acres)', dataField: 'garst', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Golden Harvest (acres)', dataField: 'golden_harvest', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Mycogen (acres)', dataField: 'mycogen', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Pioneer (acres)', dataField: 'pioneer', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
    ];
    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        const filtersGroupedByKey = groupBy(filters, 'key');
        const filtersGroupByTable = groupBy(filters, 'filter');
        var newFilter = {};
        Object.keys(filtersGroupByTable).map((filterKey) => {
            let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
            var newFilterByKey = {};
            Object.keys(filterGroupByKey).map((filKey) => {
                let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                newFilterByKey[filKey] = filterValues.toString();
            });
            newFilter[filterKey] = newFilterByKey;
        });
        newFilter["page"] = page;
        newFilter["per_page"] = 15;
        if (sortField != null && sortOrder != null) {
            newFilter["sortKey"] = sortField;
            newFilter["sortOrder"] = sortOrder;
        }
        console.log(newFilter);
        if (loggedinUser.Client != "National Corn Growers Association") {
            if (newFilter["topCounts"]) {
                newFilter["topCounts"]["associationdescription"] = loggedinUser.Client;
            } else {
                newFilter["topCounts"] = { "associationdescription": loggedinUser.Client };
            }
        }
        setFinishedCustomerListApi(false);
        axios.post(baseUrl+"list_generator/serverside_export_list", newFilter).then(result => {
            setCustomerListFilteredData(result.data.aaData);
            setFinishedCustomerListApi(true);
        });
    }

    return (
        <div>
            <Col sm={12}>
                <div id="tablediv" style={{ padding: '20px' }}>
                    <BootstrapTable
                        scrollX
                        keyField="name"
                        data={customerListFilteredValues}
                        columns={columns}
                        noDataIndication="There is no data"
                        width="200"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        remote={true}
                        pagination={paginationFactory(options)}
                        onTableChange={handleTableChange}
                    />
                </div>
            </Col>
        </div>
    )
}