import logo from './logo.svg';
import './App.css';

import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import { Container, Row, Col } from 'react-grid-system';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import HarvestComponent from './components/core/HarvestComponent/HarvestComponent';
import EntryComponent from './components/core/EntryComponent/EntryComponent';
import FilterComponent from './components/common/FilterComponent/FilterComponent';
import LoaderComponent from './components/common/LoaderComponent/LoaderComponent';
import TopCountsComponent from './components/core/TopCountsComponent/TopCountsComponent'
import DemographicsComponent from './components/core/DemographicsComponent/DemographicsComponent'
import CampaignComponent from './components/core/CampaignComponent/CampaignComponent'
import GeographyComponent from './components/core/GeographyComponent/GeographyComponent'
import LegislatorsComponent from './components/core/LegislatorsComponent/LegislatorsComponent'
import ExportCustomerListComponent from './components/core/ExportCustomerListComponent/ExportCustomerListComponent'
import MaintenanceComponent from './components/common/MaintenanceComponent/MaintenanceComponent';

import { GlobalProvider, GlobalContext } from './context/GlobalState';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {

  let query = useQuery();

  let sessionId = query.get("SessionID")
  let sessionGuid = query.get("SessionGUID")
  const { baseUrlDashboard, baseUrl, clearFilters, setEntryIds, harvestValues, setHarvestData, setHarvestFilteredData, entryValues, setEntryData, setEntryFilteredData, setUser, selectedTabValue, setSelectedTabValue, loggedinUser, customerListValues, setCustomerListFilteredDataApi, setCustomerListFilteredData, setTabData, dashboardData, setDashboardData, dashboardFilteredData, setDashboardFilteredData, cornDemographicsData, setCornDemographicsFilteredDataApi, cornDemographicsFilteredData, setCornDemographicsFilteredData, soybeanDemographicsData, setSoybeanDemographicsFilteredDataApi, soybeanDemographicsFilteredData, setSoybeanDemographicsFilteredData, cattleDemographicsData, setCattleDemographicsFilteredDataApi, cattleDemographicsFilteredData, setCattleDemographicsFilteredData, hogsDemographicsData, setHogsDemographicsFilteredDataApi, hogsDemographicsFilteredData, setHogsDemographicsFilteredData, ageDemographicsData, setAgeDemographicsFilteredDataApi, ageDemographicsFilteredData, setAgeDemographicsFilteredData, transactionDateList, setTransactionDateList, expireDateList, setExpireDateList, statusList, setStatusList, setFinishedDashboardApi,
    setFinishedCustomerListApi,
    setFinishedCornDemoApi,
    setFinishedSoyDemoApi,
    setFinishedCattleDemoApi,
    setFinishedHogsDemoApi,
    setFinishedAgeDemoApi, campaignData, setCampaignFilteredDataApi, setCampaignFilteredData, setFinishedCampaignDataApi,
    campaignResponseData, setCampaignResponseFilteredDataApi, setCampaignResponseFilteredData, setFinishedCampaignResponseApi,
    tagsAnyData, setTagsAnyFilteredDataApi, setTagsAnyFilteredData, setFinishedTagsAnyApi,
    tagsAllData, setTagsAllData, setTagsAllFilteredDataApi, setTagsAllFilteredData, setFinishedTagsAllApi,
    responseDispositionData, setResponseDispositionData, setResponseDispositionFilteredDataApi, setResponseDispositionFilteredData, setFinishedResponseDispositionApi,
    associationList, setAssociationList, setStateList, stateList,
    geographyByCounty, setGeographyByCounty, setGeographyByCountyFilteredDataApi, setGeographyByCountyFilteredData, setFinishedGeographyByCountyApi,
    geographyByGrowerDistrict, setGeographyByGrowerDistrict, setGeographyByGrowerDistrictFilteredDataApi, setGeographyByGrowerDistrictFilteredData, setFinishedGeographyByGrowerDistrictApi,
    legislatorsData, setLegislatorsData, setLegislatorsFilteredDataApi, setLegislatorsFilteredData, setFinishedLegislatorsApi,
    legislatorsStateList, setLegislatorsStateList, legislatorsBranchList, setLegislatorsBranchList, legislatorsLevelList, setLegislatorsLevelList, legislatorsDistrictList, setLegislatorsDistrictList,
    setCustomerTableFilters, finishedDashboardApi,finishedDasboardDataLoad,setFinishedDasboardDataLoad } = useContext(GlobalContext);

  let newSelectedTabValue = 0;
  if (window.location.href.includes("/#/")) {
    let hash = window.location.hash;
    if (hash.includes("#/?")) {
      newSelectedTabValue = 0;
    } else if (hash.includes("/entry")) {
      newSelectedTabValue = 1;
    } else if (hash.includes('/listgenerator') || hash.includes('/topcounts') || hash.includes('/demographics') || hash.includes('/campaign') || hash.includes('/geography') || hash.includes('/legislators') || hash.includes('/exportcustomerlist')) {
      newSelectedTabValue = 2;
    }

  } else {
    switch (window.location.pathname) {
      case '/':
        newSelectedTabValue = 0;
        break;
      case '/entry':
        newSelectedTabValue = 1;
        break;
      case '/listgenerator':
      case '/topcounts':
      case '/#/topcounts':
      case '/demographics':
      case '/campaign':
      case '/geography':
      case '/legislators':
      case '/exportcustomerlist':
        newSelectedTabValue = 2;
        break;
      default:
        newSelectedTabValue = 0;
        break;
    }
  }

  let setHarvestEntryValues = (user) => {
    if (harvestValues.length === 0) {
      let harvestData = [];
      axios.get(baseUrlDashboard + "dashboard/harvest").then(result => {
        switch (user.UserRole) {
          case 'Seed Representative':
            harvestData = result.data.aaData.filter((data) => data['HC_Voucher'] === user.Client)
            if(user.Client == 'Renk Seed'){
              harvestData = harvestData.filter((data) => data['HC_FieldState'] == 'MI')
            }
            break;
          case 'StateAdmin':
            harvestData = result.data.aaData.filter((data) => data['HC_FieldState'] === user.StateCode)
            break;
          case 'National Admin':
            harvestData = result.data.aaData
          default:
            break;
        }
        setHarvestData(harvestData);
        setHarvestFilteredData(harvestData);
      }
      );
    }
    if (entryValues.length === 0) {
      let entryData = [];
      axios.get(baseUrlDashboard + "dashboard/entry").then(result => {
        switch (user.UserRole) {
          case 'Seed Representative':
            entryData = result.data.aaData.filter((data) => data['Voucher'] === user.Client)
            if(user.Client == 'Renk Seed'){
              entryData = entryData.filter((data) => data['FieldState'] == 'MI')
            }
            if (user.Client != "DEKALB") {
              entryData.map(function (item, i) {
                delete entryData[i]["MTSA"];
              });
            }
            if (user.Client != "Pioneer") {
              entryData.map(function (item, i) {
                delete entryData[i]["SalesRepID"];
              });
            }
            break;
          case 'StateAdmin':
            entryData = result.data.aaData.filter((data) => data['FieldState'] === user.StateCode)
            break;
          case 'National Admin':
            entryData = result.data.aaData
          default:
            break;
        }
        //const saveEntryData = entryData.filter((data) => { return data.Voucher });
        setEntryData(entryData);
        setEntryFilteredData(entryData);
        setFinishedDasboardDataLoad(true);
      }
      );
    }
  }
  function setCustomerList(user) {
    let admin = "National";
    if (user.Client == "National Corn Growers Association") {
      admin = "National"
    } else {
      admin = "State"
    }
    if (dashboardData.length == 0) {
      axios.post(baseUrl + "list_generator/serverside_dashboard", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
        setDashboardData(result.data.aaData);
        setDashboardFilteredData(result.data.aaData);
        setFinishedDashboardApi(true);
      });
    }
    if (expireDateList.length == 0) {
      axios.post(baseUrl + "list_generator/expiry_date_list", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
        setExpireDateList(result.data.aaData);
      });
    }
    if (transactionDateList.length == 0) {
      axios.post(baseUrl + "list_generator/transaction_date_list", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
        setTransactionDateList(result.data.aaData);
      });
    }
    if (statusList.length == 0) {
      axios.post(baseUrl + "list_generator/sources_list", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
        setStatusList(result.data.aaData);
      });
    }
    // if (cornDemographicsData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/new_corn_demographics", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setCornDemographicsData(result.data.aaData);
    //     setCornDemographicsFilteredData(result.data.aaData);
    //     setFinishedCornDemoApi(true);
    //   });
    // }
    // if (soybeanDemographicsData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/new_soy_demographics", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setSoybeanDemographicsData(result.data.aaData);
    //     setSoybeanDemographicsFilteredData(result.data.aaData);
    //     setFinishedSoyDemoApi(true);
    //   });
    // }
    // if (cattleDemographicsData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/new_cattle_demographics", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setCattleDemographicsData(result.data.aaData);
    //     setCattleDemographicsFilteredData(result.data.aaData);
    //     setFinishedCattleDemoApi(true);
    //   });
    // }
    // if (hogsDemographicsData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/new_hogs_demographics", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setHogsDemographicsData(result.data.aaData);
    //     setHogsDemographicsFilteredData(result.data.aaData);
    //     setFinishedHogsDemoApi(true);
    //   });
    // }
    // if (ageDemographicsData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/new_age_demographics", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setAgeDemographicsData(result.data.aaData);
    //     setAgeDemographicsFilteredData(result.data.aaData);
    //     setFinishedAgeDemoApi(true);
    //   });
    // }
    // if (campaignData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/campaign_data", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setCampaignData(result.data.aaData);
    //     setCampaignFilteredData(result.data.aaData);
    //     setFinishedCampaignDataApi(true);
    //   });
    // }
    // if (campaignResponseData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/response_campaign_data", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setCampaignResponseData(result.data.aaData);
    //     setCampaignResponseFilteredData(result.data.aaData);
    //     setFinishedCampaignResponseApi(true);
    //   });
    // }
    // if (tagsAnyData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/tag_any", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setTagsAnyData(result.data.aaData);
    //     setTagsAnyFilteredData(result.data.aaData);
    //     setFinishedTagsAnyApi(true);
    //   });
    // }
    // if (tagsAllData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/tag_all", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setTagsAllData(result.data.aaData);
    //     setTagsAllFilteredData(result.data.aaData);
    //     setFinishedTagsAllApi(true);
    //   });
    // }
    // if (responseDispositionData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/response_disposition", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setResponseDispositionData(result.data.aaData);
    //     setResponseDispositionFilteredData(result.data.aaData);
    //     setFinishedResponseDispositionApi(true);
    //   });
    // } 
    // if (geographyByCounty.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/geography_county", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setGeographyByCounty(result.data.aaData);
    //     setGeographyByCountyFilteredData(result.data.aaData);
    //     setFinishedGeographyByCountyApi(true);
    //   });
    // }
    // if (geographyByGrowerDistrict.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/geography_grower_district", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setGeographyByGrowerDistrict(result.data.aaData);
    //     setGeographyByGrowerDistrictFilteredData(result.data.aaData);
    //     setFinishedGeographyByGrowerDistrictApi(true);
    //   });
    // }
    // if (legislatorsData.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/legislator_data_list", admin == "National" ? {} : { "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setLegislatorsData(result.data.aaData);
    //     setLegislatorsFilteredData(result.data.aaData);
    //     setFinishedLegislatorsApi(true);
    //   });
    // }  
    // if (customerListValues.length == 0) {
    //   axios.post("http://34.83.16.22/list_generator/serverside_export_list", admin == "National" ? {
    //     "page": 1,
    //     "per_page": 15
    //   } : { "page": 1, "per_page": 15, "topCounts": { "associationdescription": user.Client } }).then(result => {
    //     setCustomerListData(result.data.aaData);
    //     setCustomerListFilteredData(result.data.aaData);
    //     setFinishedCustomerListApi(true);
    //     if (result.data.aaData.length != 0) {
    //       let allFields = Object.keys(result.data.aaData[0]);
    //       allFields.forEach(function (item) {
    //         axios.post("http://34.83.16.22/list_generator/get_table_column_values", admin == "National" ? { key: item } : { key: item, "topCounts": { "associationdescription": user.Client } }).then(result => {
    //           let filt = {};
    //           filt[item] = result.data.aaData;
    //           setCustomerTableFilters(filt);
    //         });
    //       });
    //     }
    //   });
    // }
  }
  useEffect(() => {
    if (sessionGuid && sessionId) {
      axios.get(baseUrlDashboard + "dashboard/user?SessionID=" + sessionId + "&SessionGUID=" + sessionGuid).then(result => {
        setTabData(newSelectedTabValue);
        setUser(result.data[0]);
        if (newSelectedTabValue == 0 || newSelectedTabValue == 1) {
          setHarvestEntryValues(result.data[0]);
          sessionStorage.setItem("UserRole", result.data[0].UserRole);
          sessionStorage.setItem("Client", result.data[0].Client);
        } else {
          setCustomerList(result.data[0]);
        }
      }
      );
    }

  }, []);
  const AntTabs = withStyles({
    indicator: {
      backgroundColor: '#3a8104 !important',
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#fff',
        opacity: 1,
      },
      '&$selected': {
        color: '#fff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#fff',
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);
  return (
    <Router>
      <AppBar position="static">
        {(newSelectedTabValue == 0 || newSelectedTabValue == 1) &&
          <Row className="headerDiv">
            <Col sm={8}>
              <h2 className="textalignLeft col-sm-8">{loggedinUser.UserRole !== 'National Admin' ? loggedinUser.Client : 'Yield Contest'}</h2>
            </Col>
            <Col sm={4}>
              <AntTabs className="headerTabs" value={selectedTabValue} onChange={(event, value) => { setSelectedTabValue(value) }}>
                <AntTab label="Entry" to={`/?SessionGUID=${sessionGuid}&SessionID=${sessionId}`} component={Link} />
                <AntTab label="Harvest" to={`/harvest?SessionGUID=${sessionGuid}&SessionID=${sessionId}`} component={Link} />
                <span class="clear-all" onClick={() => {
                  setEntryIds([]);
                  setHarvestFilteredData(harvestValues);
                  setEntryFilteredData(entryValues);
                  clearFilters();
                }}>Clear All</span>
              </AntTabs>
            </Col>
          </Row>
        }
        {newSelectedTabValue != 0 && newSelectedTabValue != 1 &&
          <Row className="headerDiv">
            <Col sm={4}>
              <h2 className="textalignLeft col-sm-8"></h2>
            </Col>
            <Col sm={8}>
              <AntTabs className="headerTabs" value={selectedTabValue} onChange={(event, value) => { setSelectedTabValue(value) }}>
                <AntTab label="Top Counts" to={`/topcounts?SessionGUID=${sessionGuid}&SessionID=${sessionId}`} component={Link} />
                <AntTab label="Demographics" to={`/demographics?SessionGUID=${sessionGuid}&SessionID=${sessionId}`} component={Link} />
                <AntTab label="Campaign" to={`/campaign?SessionGUID=${sessionGuid}&SessionID=${sessionId}`} component={Link} />
                <AntTab label="Geography" to={`/geography?SessionGUID=${sessionGuid}&SessionID=${sessionId}`} component={Link} />
                <AntTab label="Legislators" to={`/legislators?SessionGUID=${sessionGuid}&SessionID=${sessionId}`} component={Link} />
                <AntTab label="Export Customer List" to={`/exportcustomerlist?SessionGUID=${sessionGuid}&SessionID=${sessionId}`} component={Link} />
                <span class="clear-all" onClick={() => {
                  let path = window.location.pathname;
                  setEntryIds([]);
                  switch (path) {
                    case "/topcounts":
                      setDashboardFilteredData(dashboardData);
                      break;
                    case "/demographics":
                      if (cornDemographicsData.length != 0) {
                        setCornDemographicsFilteredData(cornDemographicsData);
                      } else {
                        setFinishedCornDemoApi(false);
                        setCornDemographicsFilteredDataApi([]);
                      }
                      if (soybeanDemographicsData.length != 0) {
                        setSoybeanDemographicsFilteredData(soybeanDemographicsData);
                      } else {
                        setFinishedSoyDemoApi(false);
                        setSoybeanDemographicsFilteredDataApi([]);
                      }
                      if (cattleDemographicsData.length != 0) {
                        setCattleDemographicsFilteredData(cattleDemographicsData);
                      } else {
                        setFinishedCattleDemoApi(false);
                        setCattleDemographicsFilteredDataApi([]);
                      }
                      if (hogsDemographicsData.length != 0) {
                        setHogsDemographicsFilteredData(hogsDemographicsData);
                      } else {
                        setFinishedHogsDemoApi(false);
                        setHogsDemographicsFilteredDataApi([]);
                      }
                      if (ageDemographicsData.length != 0) {
                        setAgeDemographicsFilteredData(ageDemographicsData);
                      } else {
                        setFinishedAgeDemoApi(false);
                        setAgeDemographicsFilteredDataApi([]);
                      }
                      break;
                    case "/campaign":
                      if (campaignData.length != 0) {
                        setCampaignFilteredData(campaignData);
                      } else {
                        setFinishedCampaignDataApi(false);
                        setCampaignFilteredDataApi([]);
                      }
                      if (campaignResponseData.length != 0) {
                        setCampaignResponseFilteredData(campaignResponseData);
                      } else {
                        setFinishedCampaignResponseApi(false);
                        setCampaignResponseFilteredDataApi([]);
                      }
                      if (tagsAnyData.length != 0) {
                        setTagsAnyFilteredData(tagsAnyData);
                      } else {
                        setFinishedTagsAnyApi(false);
                        setTagsAnyFilteredDataApi([]);
                      }
                      if (tagsAllData.length != 0) {
                        setTagsAllFilteredData(tagsAllData);
                      } else {
                        setFinishedTagsAllApi(false);
                        setTagsAllFilteredDataApi([]);
                      }
                      if (responseDispositionData.length != 0) {
                        setResponseDispositionFilteredData(responseDispositionData);
                      } else {
                        setFinishedResponseDispositionApi(false);
                        setResponseDispositionFilteredDataApi([]);
                      }
                      break;
                    case "/geography":
                      if (geographyByCounty.length != 0) {
                        setGeographyByCountyFilteredData(geographyByCounty);
                      } else {
                        setFinishedGeographyByCountyApi(false);
                        setGeographyByCountyFilteredDataApi([]);
                      }
                      if (geographyByGrowerDistrict.length != 0) {
                        setGeographyByGrowerDistrictFilteredData(geographyByGrowerDistrict);
                      } else {
                        setFinishedGeographyByGrowerDistrictApi(false);
                        setGeographyByGrowerDistrictFilteredDataApi([]);
                      }
                      break;
                    case "/legislators":
                      if (legislatorsData.length != 0) {
                        setLegislatorsFilteredData(legislatorsData);
                      } else {
                        setFinishedLegislatorsApi(false);
                        setLegislatorsFilteredDataApi([]);
                      }
                      setDashboardFilteredData(dashboardData);
                      break;
                    case "/exportcustomerlist":
                      if (customerListValues.length != 0) {
                        setCustomerListFilteredData(customerListValues);
                      } else {
                        setFinishedCustomerListApi(false);
                        setCustomerListFilteredDataApi([]);
                      }
                      setDashboardFilteredData(dashboardData);
                      break;
                    default:
                      break;
                  }
                  clearFilters();
                }}>Clear All</span>
              </AntTabs>
            </Col>
          </Row>
        }
      </AppBar>
      {(newSelectedTabValue === 0 || newSelectedTabValue === 1) && finishedDasboardDataLoad === false && <LoaderComponent />}
      {(newSelectedTabValue !== 0 && newSelectedTabValue !== 1) && dashboardData.length === 0 && <LoaderComponent />}
      {(newSelectedTabValue === 0 || newSelectedTabValue === 1) && finishedDasboardDataLoad === true && sessionGuid && sessionId &&
        <div>
          <FilterComponent></FilterComponent>
          <Switch>
            <Route path="/harvest" exact>
              <HarvestComponent />
            </Route>
            <Route path="/" exact>
              <EntryComponent />
            </Route>
          </Switch>
        </div>}
      {(newSelectedTabValue != 0 && newSelectedTabValue != 1) && dashboardData.length !== 0 && sessionGuid && sessionId &&
        <div>
          <FilterComponent></FilterComponent>
          <Switch>
            <Route path="/topcounts" exact>
              <TopCountsComponent />
            </Route>
            <Route path="/listgenerator" exact>
              <TopCountsComponent />
            </Route>
            <Route path="/demographics" exact>
              <DemographicsComponent />
            </Route>
            <Route path="/campaign" exact>
              <CampaignComponent />
            </Route>
            <Route path="/geography" exact>
              <GeographyComponent />
            </Route>
            <Route path="/legislators" exact>
              <LegislatorsComponent />
            </Route>
            <Route path="/exportcustomerlist" exact>
              <ExportCustomerListComponent />
            </Route>
            <Route path="/" exact>
              <TopCountsComponent />
            </Route>
            <Route path="/#/topcounts" exact>
              <TopCountsComponent />
            </Route>
          </Switch>
        </div>}

      {(!sessionGuid || !sessionId) &&
        <div class="not-logged-in">
          User not logged in
      </div>}
    </Router>
  );
}

export default App;
