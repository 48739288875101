import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { CSVLink } from "react-csv";
import { groupBy } from '../../../utility/utility';
import { GlobalContext } from '../../../context/GlobalState';
import axios from "axios";

export default function ExportComponent() {
    const { baseUrl,loggedinUser, selectedTabValue, entryFilteredValues, harvestFilteredValues, customerListFilteredValues, filters, finishedExportApi, setFinishedExportApi } = useContext(GlobalContext);
    let path = window.location.pathname;
    let location = "ncyc";
    if (path == '/' || path == '/entry' || path == '/harvest') {
        location = "ncyc";
    } else {
        location = "listgenerator";
    }
    function generateExcel() {
        setFinishedExportApi(false);
        if (filters.length != 0) {
            const filtersGroupedByKey = groupBy(filters, 'key');
            const filtersGroupByTable = groupBy(filters, 'filter');
            var newFilter = {};
            Object.keys(filtersGroupByTable).map((filterKey) => {
                let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                var newFilterByKey = {};
                Object.keys(filterGroupByKey).map((filKey) => {
                    let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                    newFilterByKey[filKey] = filterValues.toString();
                });
                newFilter[filterKey] = newFilterByKey;
            });
            if (loggedinUser.Client != "National Corn Growers Association") {
                if (newFilter["topCounts"]) {
                    newFilter["topCounts"]["associationdescription"] = loggedinUser.Client;
                } else {
                    newFilter["topCounts"] = { "associationdescription": loggedinUser.Client };
                }
            }
            axios.post(baseUrl+"list_generator/xlsx", newFilter, { responseType: 'blob' }).then(result => {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                setFinishedExportApi(true);
            });
        } else {
            let inputExport = {};
            if (loggedinUser.Client != "National Corn Growers Association") {
                newFilter["topCounts"] = { "associationdescription": loggedinUser.Client };
            }
            axios.post(baseUrl+"list_generator/xlsx", inputExport, { responseType: 'blob' }).then(result => {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                setFinishedExportApi(true);
            });
        }
    }
    return (
        <div>
            <Row className="boxPadding">
                <Col sm={12}>
                    {location === "ncyc" && <CSVLink data-id={selectedTabValue} className="exportButton btn btn-success" id="btnExportEntry" filename="data.csv" data={selectedTabValue === 0 ? entryFilteredValues : harvestFilteredValues}>Export</CSVLink>}
                    {location === "listgenerator" && <button className="exportButton btn btn-success" onClick={() => { generateExcel() }}>Export</button>}
                </Col>
            </Row>
        </div>
    )
}