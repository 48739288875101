import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import BootstrapTable from 'react-bootstrap-table-next';
import Popup from 'reactjs-popup';
import { BarChart, Cell, Tooltip, CartesianGrid, XAxis, YAxis, Bar } from 'recharts'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import { GlobalContext } from '../../../context/GlobalState';
import { groupBy } from '../../../utility/utility';

export default function CampaignResponseDataComponent() {
    const { addFilter, addNewFilter, filters, removeFilter, removeNewFilter, setEntryIds, campaignResponseData, campaignResponseFilteredData, setCampaignResponseFilteredData, setCampaignResponseFilteredDataApi, setFinishedCampaignResponseApi, campaignData, campaignFilteredData, setCampaignFilteredData, setCampaignFilteredDataApi, setFinishedCampaignDataApi,
        tagsAnyData, setTagsAnyFilteredDataApi, setTagsAnyFilteredData, setFinishedTagsAnyApi,
        tagsAllData, tagsAllFilteredData, setTagsAllFilteredDataApi, setTagsAllFilteredData, setFinishedTagsAllApi,
        responseDispositionData, responseDispositionFilteredData, setResponseDispositionFilteredDataApi, setResponseDispositionFilteredData, setFinishedResponseDispositionApi,
    } = useContext(GlobalContext);
    const [campaignResponseCounts, setCampaignResponseCounts] = useState(0);
    const [campaignRangeData, setCampaignRangeData] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [filterCounts, setFilterCounts] = useState(0)
    function headerContent(column, colIndex, onFilter) {
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="bottom middle"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"campaignResponseData"} close={close} column={column} columnOptionParent={campaignResponseData} />
                    </div>}

                </Popup>
            </div>
        );
    }
    const columns = [
        {
            text: 'Campaign Range', dataField: 'campaignrange', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }, footer: 'Total'
        },
        {
            text: 'Total', dataField: 'campaignrangetotal', sort: true, formatter: (col, row) => { return col.toLocaleString() }, footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        }
    ];
    const colorsBarChart = ["rgb(68,119,170)"];
    const defaultSorted = [{
        dataField: 'campaignrange', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];
    const DataFormater = (number) => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    }
    useEffect(() => {
        let localFilters = filters;
        if (localFilters.length != 0) {
            const filtersGroupedByKey = groupBy(localFilters, 'key');
            const filtersGroupByTable = groupBy(localFilters, 'filter');
            var newFilter = {};
            Object.keys(filtersGroupByTable).map((filterKey) => {
                let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                var newFilterByKey = {};
                Object.keys(filterGroupByKey).map((filKey) => {
                    let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                    newFilterByKey[filKey] = filterValues.toString();
                });
                newFilter[filterKey] = newFilterByKey;
            });
            setFinishedCampaignResponseApi(false);
            setCampaignResponseFilteredDataApi(newFilter);
        } else {
            if (campaignResponseData.length != 0) {
                setCampaignResponseFilteredData(campaignResponseData);
            } else {
                setFinishedCampaignResponseApi(false);
                setCampaignResponseFilteredDataApi([]);
            }
        }
    }, []);
    useEffect(() => {
        let campaignResponseDataCount = campaignResponseFilteredData.reduce((sum, item) => sum += item.campaignrangetotal, 0);
        if (campaignResponseDataCount != campaignResponseCounts) {
            let campaignRangeTop5 = campaignResponseFilteredData.sort((a, b) => b.campaignrangetotal - a.campaignrangetotal).slice(0, 5);
            console.log(campaignRangeTop5);
            let objTop5 = [];
            objTop5 = campaignRangeTop5.map((val) => {
                let campaign = { name: val.campaignrange, value: val.campaignrangetotal };
                return campaign;
            });
            setCampaignResponseCounts(campaignResponseDataCount);
            setCampaignRangeData(campaignResponseFilteredData);
            setBarChartData(objTop5);
        }
    }, [campaignResponseFilteredData]);
    const filterChart = (name, key) => {
        if (name != "brush") {
            let localFilter = filters;
            filters.map((filter) => {
                if (filter.filter == 'campaignResponseData' && filter.key == key) {
                    removeFilter(filter.key);
                    removeNewFilter(filter.key);
                    localFilter = localFilter.filter(item => (item.key !== filter.key));
                }
            });
            addFilter({ key: key, value: name, filter: 'campaignResponseData' });
            addNewFilter({ key: key, value: name, filter: 'campaignResponseData' });
            localFilter.push({ key: key, value: name, filter: 'campaignResponseData' });
            setFilterCounts(filterCounts + 1);
            if (localFilter.length != 0) {
                const filtersGroupedByKey = groupBy(localFilter, 'key');
                const filtersGroupByTable = groupBy(localFilter, 'filter');
                var newFilter = {};
                Object.keys(filtersGroupByTable).map((filterKey) => {
                    let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                    var newFilterByKey = {};
                    Object.keys(filterGroupByKey).map((filKey) => {
                        let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                        newFilterByKey[filKey] = filterValues.toString();
                    });
                    newFilter[filterKey] = newFilterByKey;
                });
                setFinishedCampaignDataApi(false);
                setFinishedCampaignResponseApi(false);
                setFinishedTagsAnyApi(false);
                setFinishedTagsAllApi(false);
                setFinishedResponseDispositionApi(false);
                setCampaignFilteredDataApi(newFilter);
                setCampaignResponseFilteredDataApi(newFilter);
                setTagsAnyFilteredDataApi(newFilter);
                setTagsAllFilteredDataApi(newFilter);
                setResponseDispositionFilteredDataApi(newFilter);
            } else {
                setCampaignFilteredData(campaignData);
                setCampaignResponseFilteredData(campaignResponseData);
                setTagsAnyFilteredData(tagsAnyData);
                setTagsAllFilteredData(tagsAllData);
                setResponseDispositionFilteredData(responseDispositionData);
            }
        }
    }
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip">
                    <p className="bold">{`${payload[0].name}`}</p>
                    <p className="label">Campaign Type : {`${payload[0].name}`}</p>
                    <p className="desc">Total : {`${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };
    return (
        <div style={{ width: '50%' }}>
            <Col sm={6} style={{ float: 'left' }} id="campaignResponseData">
                <div id="tablediv" style={{ padding: '5px', paddingTop: "10px" }}>
                    <h5>Response - Campaign</h5>
                    <BootstrapTable
                        scrollX
                        keyField="name"
                        data={campaignRangeData}
                        columns={columns}
                        noDataIndication="There is no data"
                        width="200"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        defaultSorted={defaultSorted}
                    />
                </div>
            </Col>
            <Col sm={6} style={{ float: 'left' }}>
                <h5 className="textalignleft">Top 5 Response - Campaign Chart</h5>
                <BarChart width={310} height={400} data={barChartData} onClick={(e) => { filterChart(e == null ? "brush" : e.activeLabel, 'campaignrange') }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" textAnchor="end" tick={{ angle: -15 }} interval={0} height={75} style={{ height: '200px', fontSize: '14px' }} />
                    <YAxis tickFormatter={DataFormater} />
                    <Tooltip
                        formatter={(value) =>
                            DataFormater(value)
                        } />
                    <Bar dataKey="value" name="Voucher">
                        {barChartData.map((entry, index) => <Cell fill={colorsBarChart[index % colorsBarChart.length]} />)}
                    </Bar>
                </BarChart>
            </Col>
        </div>
    )
}