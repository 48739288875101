import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter, multiSelectFilter, customFilter, Comparator, FILTER_TYPES } from "react-bootstrap-table2-filter";
import { React, useState, useEffect, useContext } from 'react';
import { Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from "axios";

import { GlobalContext } from '../../../context/GlobalState';
import { groupBy } from '../../../utility/utility';
import './ColumnCustomFilter.css';

export default function ColumnCustomFilter(props) {
    const [entryCounts, setEntryCounts] = useState(0)
    const [filterCounts, setFilterCounts] = useState(0)
    const [customerCounts, setCustomerCounts] = useState(0)
    const { baseUrl,entryFilteredValues, setEntryFilteredData, setHarvestFilteredData, harvestFilteredValues, filters, setEntryIds, addFilter, addNewFilter, removeFilter, removeNewFilter, entryValues, harvestValues, newFilters, customerListFilteredValues, setCustomerListFilteredData, setCustomerListFilteredDataApi, customerListValues, cornDemographicsData, cornDemographicsFilteredData, setCornDemographicsFilteredData, setCornDemographicsFilteredDataApi, soybeanDemographicsData, soybeanDemographicsFilteredData, setSoybeanDemographicsFilteredData, setSoybeanDemographicsFilteredDataApi, dashboardData, setDashboardData, dashboardFilteredData, setDashboardFilteredData, setDashboardFilteredDataApi, cattleDemographicsData, setCattleDemographicsFilteredData, setCattleDemographicsFilteredDataApi, hogsDemographicsData, setHogsDemographicsFilteredData, setHogsDemographicsFilteredDataApi, ageDemographicsData, setAgeDemographicsFilteredData, setAgeDemographicsFilteredDataApi, setFinishedDashboardApi, setFinishedCornDemoApi, setFinishedSoyDemoApi, setFinishedCattleDemoApi, setFinishedHogsDemoApi, setFinishedAgeDemoApi, setFinishedCustomerListApi, campaignData, campaignFilteredData, setCampaignFilteredData, setCampaignFilteredDataApi, setFinishedCampaignDataApi,
        campaignResponseData, setCampaignResponseFilteredDataApi, setCampaignResponseFilteredData, setFinishedCampaignResponseApi,
        tagsAnyData, setTagsAnyFilteredDataApi, setTagsAnyFilteredData, setFinishedTagsAnyApi,
        tagsAllData, tagsAllFilteredData, setTagsAllFilteredDataApi, setTagsAllFilteredData, setFinishedTagsAllApi,
        responseDispositionData, responseDispositionFilteredData, setResponseDispositionFilteredDataApi, setResponseDispositionFilteredData, setFinishedResponseDispositionApi,
        geographyByCounty, setGeographyByCountyFilteredDataApi, setGeographyByCountyFilteredData, setFinishedGeographyByCountyApi,
        geographyByGrowerDistrict, setGeographyByGrowerDistrictFilteredDataApi, setGeographyByGrowerDistrictFilteredData, setFinishedGeographyByGrowerDistrictApi,
        legislatorsData, legislatorsFilteredData, setLegislatorsFilteredDataApi, setLegislatorsFilteredData, setFinishedLegislatorsApi, tempFilters, addTempFilter, removeTempFilter, clearTempFilters, tempFiltersUnchecked, addTempFilterUnchecked, removeTempFilterUnchecked, clearTempFiltersUnchecked,
        setCornDemographicsDataApi, setSoybeanDemographicsDataApi, setCattleDemographicsDataApi, setHogsDemographicsDataApi, setAgeDemographicsDataApi, setCampaignDataApi, setCampaignResponseDataApi, setTagsAnyDataApi, setTagsAllDataApi,
        setResponseDispositionDataApi, setGeographyByCountyDataApi, setGeographyByGrowerDistrictDataApi, setLegislatorsDataApi, customerTableFilters, loggedinUser, setCustomerTableFilters } = useContext(GlobalContext);
    useEffect(() => {
        let path = window.location.pathname;
        if (path == '/' || path == '/entry' || path == '/harvest') {
            if (entryFilteredValues.length !== entryCounts || filterCounts !== filters.length) {
                setEntryCounts(entryFilteredValues.length)
                setFilterCounts(filters.length)
            }
        } else {
            if (customerListFilteredValues.length !== customerCounts || filterCounts !== filters.length) {
                setCustomerCounts(customerListFilteredValues.length)
                setFilterCounts(filters.length)
            }
        }
    }, []);
    function filterSelections() {
        clearTempFilters();
        clearTempFiltersUnchecked();
        filterTable(props.column.dataField, node.selectionContext.selected);
    }
    function filterTable(key, values) {
        // const newFilteredData = findFilteredData(props.table == "entry" ? entryFilteredValues : harvestFilteredValues, values, key);
        // var filterIds = newFilteredData.map((el) => { return el.EntryContestID; });
        // setEntryIds(filterIds); 
        let localFilters = filters;
        filters.map((filter) => {
            if (filter.type === 'table' && filter.filter == props.table && filter.key == key) {
                removeFilter(filter.key);
                removeNewFilter(filter.key);
                localFilters = localFilters.filter(item => (item.key !== filter.key));
            }
        });
        values.map((value) => {
            addFilter({ key: key, value: value, filter: props.table, type: 'table' });
            addNewFilter({ key: key, value: value, filter: props.table });
            setFilterCounts(filterCounts + 1)
            localFilters.push({ key: key, value: value, filter: props.table, type: 'table' });
        });

        let path = window.location.pathname;
        if (path == '/' || path == '/entry' || path == '/harvest') {
            if (localFilters.length != 0) {
                const filtersGroupedByKey = groupBy(localFilters, 'key');
                let newHarvestFilteredData = harvestValues;
                let newEntryFilteredData = entryValues;
                Object.keys(filtersGroupedByKey).map((filterKey) => {
                    let filterValuesHarvest = filtersGroupedByKey[filterKey].map((filterItem) => { if (filterItem.filter == "harvest") return filterItem.value });
                    if (filterValuesHarvest.length != 0) {
                        newHarvestFilteredData = newHarvestFilteredData.filter((d) => { return filterValuesHarvest.includes(d[filterKey]) });
                    }
                    let filterValuesEntry = filtersGroupedByKey[filterKey].map((filterItem) => { if (filterItem.filter == "entry") return filterItem.value });
                    if (filterValuesEntry.length != 0) {
                        newEntryFilteredData = newEntryFilteredData.filter((d) => { return filterValuesEntry.includes(d[filterKey]) });
                    }
                });
                var harvestFilterIds = newHarvestFilteredData.map((el) => { return el.EntryContestID; });
                var entryFilterIds = newEntryFilteredData.map((el) => { return el.EntryContestID; });
                //let filterIds = [...new Set([...harvestFilterIds, ...entryFilterIds])];
                //const filterIds = entryFilterIds.filter(value => harvestFilterIds.includes(value));
                console.log(newHarvestFilteredData);
                console.log(newEntryFilteredData);
                var filterIds=[];
                if(newHarvestFilteredData.length==harvestValues.length){
                    filterIds=entryFilterIds;
                }else{
                    filterIds = entryFilterIds.filter(value => harvestFilterIds.includes(value));
                }
                console.log(harvestFilterIds.length);
                console.log(entryFilterIds.length);
                console.log(filterIds.length);
                if (filterIds.length > 0) {
                    setEntryIds(filterIds);
                } else {
                    setEntryIds([]);
                }
                setHarvestFilteredData(newHarvestFilteredData);
                setEntryFilteredData(newEntryFilteredData);
            } else {
                setEntryIds([]);
                setHarvestFilteredData(harvestValues);
                setEntryFilteredData(entryValues);
            }
        } else {
            console.log(localFilters);
            if (localFilters.length != 0) {
                const filtersGroupedByKey = groupBy(localFilters, 'key');
                const filtersGroupByTable = groupBy(localFilters, 'filter');
                var newFilter = {};
                Object.keys(filtersGroupByTable).map((filterKey) => {
                    let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                    var newFilterByKey = {};
                    Object.keys(filterGroupByKey).map((filKey) => {
                        let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                        newFilterByKey[filKey] = filterValues.toString();
                    });
                    newFilter[filterKey] = newFilterByKey;
                });
                switch (path) {
                    case "/topcounts":
                        setFinishedDashboardApi(false);
                        setDashboardFilteredDataApi(newFilter);
                        break;
                    case "/demographics":
                        setFinishedCornDemoApi(false);
                        setFinishedSoyDemoApi(false);
                        setFinishedCattleDemoApi(false);
                        setFinishedHogsDemoApi(false);
                        setFinishedAgeDemoApi(false);
                        setCornDemographicsFilteredDataApi(newFilter);
                        setSoybeanDemographicsFilteredDataApi(newFilter);
                        setCattleDemographicsFilteredDataApi(newFilter);
                        setHogsDemographicsFilteredDataApi(newFilter);
                        setAgeDemographicsFilteredDataApi(newFilter);
                        break;
                    case "/campaign":
                        setFinishedCampaignDataApi(false);
                        setFinishedCampaignResponseApi(false);
                        setFinishedTagsAnyApi(false);
                        setFinishedTagsAllApi(false);
                        setFinishedResponseDispositionApi(false);
                        setCampaignFilteredDataApi(newFilter);
                        setCampaignResponseFilteredDataApi(newFilter);
                        setTagsAnyFilteredDataApi(newFilter);
                        setTagsAllFilteredDataApi(newFilter);
                        setResponseDispositionFilteredDataApi(newFilter);
                        break;
                    case "/geography":
                        setFinishedGeographyByCountyApi(false);
                        setFinishedGeographyByGrowerDistrictApi(false);
                        setGeographyByCountyFilteredDataApi(newFilter);
                        setGeographyByGrowerDistrictFilteredDataApi(newFilter);
                        break;
                    case "/legislators":
                        setFinishedLegislatorsApi(false);
                        setLegislatorsFilteredDataApi(newFilter);
                        setFinishedDashboardApi(false);
                        setDashboardFilteredDataApi(newFilter);
                        break;
                    case "/exportcustomerlist":
                        setFinishedDashboardApi(false);
                        setDashboardFilteredDataApi(newFilter);
                        setFinishedCustomerListApi(false);
                        setCustomerListFilteredDataApi(newFilter);
                        break;
                    default:
                        break;

                }
            } else {
                switch (path) {
                    case "/topcounts":
                        setDashboardFilteredData(dashboardData);
                        break;
                    case "/demographics":
                        if (cornDemographicsData.length != 0) {
                            setCornDemographicsFilteredData(cornDemographicsData);
                        } else {
                            setFinishedCornDemoApi(false);
                            setCornDemographicsFilteredDataApi([]);
                        }
                        if (soybeanDemographicsData.length != 0) {
                            setSoybeanDemographicsFilteredData(soybeanDemographicsData);
                        } else {
                            setFinishedSoyDemoApi(false);
                            setSoybeanDemographicsFilteredDataApi([]);
                        }
                        if (cattleDemographicsData.length != 0) {
                            setCattleDemographicsFilteredData(cattleDemographicsData);
                        } else {
                            setFinishedCattleDemoApi(false);
                            setCattleDemographicsFilteredDataApi([]);
                        }
                        if (hogsDemographicsData.length != 0) {
                            setHogsDemographicsFilteredData(hogsDemographicsData);
                        } else {
                            setFinishedHogsDemoApi(false);
                            setHogsDemographicsFilteredDataApi([]);
                        }
                        if (ageDemographicsData.length != 0) {
                            setAgeDemographicsFilteredData(ageDemographicsData);
                        } else {
                            setFinishedAgeDemoApi(false);
                            setAgeDemographicsFilteredDataApi([]);
                        }
                        break;
                    case "/campaign":
                        if (campaignData.length != 0) {
                            setCampaignFilteredData(campaignData);
                        } else {
                            setFinishedCampaignDataApi(false);
                            setCampaignFilteredDataApi([]);
                        }
                        if (campaignResponseData.length != 0) {
                            setCampaignResponseFilteredData(campaignResponseData);
                        } else {
                            setFinishedCampaignResponseApi(false);
                            setCampaignResponseFilteredDataApi([]);
                        }
                        if (tagsAnyData.length != 0) {
                            setTagsAnyFilteredData(tagsAnyData);
                        } else {
                            setFinishedTagsAnyApi(false);
                            setTagsAnyFilteredDataApi([]);
                        }
                        if (tagsAllData.length != 0) {
                            setTagsAllFilteredData(tagsAllData);
                        } else {
                            setFinishedTagsAllApi(false);
                            setTagsAllFilteredDataApi([]);
                        }
                        if (responseDispositionData.length != 0) {
                            setResponseDispositionFilteredData(responseDispositionData);
                        } else {
                            setFinishedResponseDispositionApi(false);
                            setResponseDispositionFilteredDataApi([]);
                        }
                        break;
                    case "/geography":
                        if (geographyByCounty.length != 0) {
                            setGeographyByCountyFilteredData(geographyByCounty);
                        } else {
                            setFinishedGeographyByCountyApi(false);
                            setGeographyByCountyFilteredDataApi([]);
                        }
                        if (geographyByGrowerDistrict.length != 0) {
                            setGeographyByGrowerDistrictFilteredData(geographyByGrowerDistrict);
                        } else {
                            setFinishedGeographyByGrowerDistrictApi(false);
                            setGeographyByGrowerDistrictFilteredDataApi([]);
                        }
                        break;
                    case "/legislators":
                        if (legislatorsData.length != 0) {
                            setLegislatorsFilteredData(legislatorsData);
                        } else {
                            setFinishedLegislatorsApi(false);
                            setLegislatorsFilteredDataApi([]);
                        }
                        setDashboardFilteredData(dashboardData);
                        break;
                    case "/exportcustomerlist":
                        if (customerListValues.length != 0) {
                            setCustomerListFilteredData(customerListValues);
                        } else {
                            setFinishedCustomerListApi(false);
                            setCustomerListFilteredDataApi([]);
                        }
                        setDashboardFilteredData(dashboardData);
                        break;
                    default:
                        break;

                }
            }
        }
    }
    const findFilteredData = (arr1, arr2, key) => {
        var filtered = arr1;
        if (arr2.length != 0)
            filtered = arr1.filter((data) => { return arr2.indexOf(data[key]) != -1 });
        return filtered;
    }
    const columns = [{
        dataField: props.column.dataField,
        text: props.column.text,
        filter: textFilter(),
        formatter: (value, row) => {
            if (props.table == "cornDemographics") {
                return "Corn - " + value;
            } else if (props.table == "soybeanDemographics") {
                return "Soybeans - " + value;
            } else if (props.table == "cattleDemographics") {
                return "Cattle - " + value;
            } else if (props.table == "hogsDemographics") {
                return "Hogs - " + value;
            } else if (props.table == "ageDemographics") {
                return "Age - " + value;
            } else {
                return value;
            }
        }
    }];
    const defaultSorted = [{
        dataField: props.column.dataField, // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];
    var selectedFilters = filters.filter((filter) => {
        return (filter.filter == props.table && filter.key == props.column.dataField);
    });
    selectedFilters = selectedFilters.map((el) => { return el.value; }).filter((value, index, self) => self.indexOf(value) === index);
    var selectedRows;
    if (selectedFilters) {
        selectedRows = selectedFilters.concat(tempFilters);
        console.log("1");
    } else {
        selectedRows = tempFilters;
        console.log("2");
    }
    console.log(tempFiltersUnchecked);
    if (tempFiltersUnchecked.length != 0) {
        selectedRows = selectedRows.filter(function (value, index) {
            return tempFiltersUnchecked.indexOf(value) <= -1;
        })
    }
    console.log(tempFilters);
    selectedRows = selectedRows.filter((value, index, self) => self.indexOf(value) === index)
    console.log(selectedRows);
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row[props.column.dataField]);
            if (isSelect) {
                if (selectedRows.indexOf(row[props.column.dataField] <= -1)) {
                    addTempFilter(row[props.column.dataField]);
                    if (tempFiltersUnchecked.indexOf(row[props.column.dataField] > -1)) {
                        removeTempFilterUnchecked(row[props.column.dataField]);
                    }
                }
            } else {
                removeTempFilter(row[props.column.dataField]);
                if (selectedFilters.indexOf(row[props.column.dataField]) > -1 && tempFiltersUnchecked.indexOf(row[props.column.dataField]) <= -1) {
                    addTempFilterUnchecked(row[props.column.dataField]);
                    console.log("un");
                }
            }
        },
        style: { backgroundColor: 'rgb(70, 198, 70)' },
        selected: selectedRows
    };
    var node;
    let options
    useEffect(() => {
        switch (props.table) {
            case "cornDemographics":
                if (cornDemographicsData.length == 0) {
                    setFinishedCornDemoApi(false);
                    setCornDemographicsDataApi();
                }
                break;
            case "soybeanDemographics":
                if (soybeanDemographicsData.length == 0) {
                    setFinishedSoyDemoApi(false);
                    setSoybeanDemographicsDataApi();
                }
                break;
            case "cattleDemographics":
                if (cattleDemographicsData.length == 0) {
                    setFinishedCattleDemoApi(false);
                    setCattleDemographicsDataApi();
                }
                break;
            case "hogsDemographics":
                if (hogsDemographicsData.length == 0) {
                    setFinishedHogsDemoApi(false);
                    setHogsDemographicsDataApi();
                }
                break;
            case "ageDemographics":
                if (ageDemographicsData.length == 0) {
                    setFinishedAgeDemoApi(false);
                    setAgeDemographicsDataApi();
                }
                break;
            case "campaignData":
                if (campaignData.length == 0) {
                    setFinishedCampaignDataApi(false);
                    setCampaignDataApi();
                }
                break;
            case "campaignResponseData":
                if (campaignResponseData.length == 0) {
                    setFinishedCampaignResponseApi(false);
                    setCampaignResponseDataApi();
                }
                break;
            case "tagsAnyData":
                if (tagsAnyData.length == 0) {
                    setFinishedTagsAnyApi(false);
                    setTagsAnyDataApi();
                }
                break;
            case "tagsAllData":
                if (tagsAllData.length == 0) {
                    setFinishedTagsAllApi(false);
                    setTagsAllDataApi();
                }
                break;
            case "responseDispositionData":
                if (responseDispositionData.length == 0) {
                    setFinishedResponseDispositionApi(false);
                    setResponseDispositionDataApi();
                }
                break;
            case "geographyData":
                if (geographyByCounty.length == 0) {
                    setFinishedGeographyByCountyApi(false);
                    setGeographyByCountyDataApi();
                }
                if (geographyByGrowerDistrict.length == 0) {
                    setFinishedGeographyByGrowerDistrictApi(false);
                    setGeographyByGrowerDistrictDataApi();
                }
                break;
            case "legislatorsData":
                if (legislatorsData.length == 0) {
                    setFinishedLegislatorsApi(false);
                    setLegislatorsDataApi();
                }
                break;
            case "customerList":
                if (!customerTableFilters[props.column.dataField]) {
                    setFinishedCustomerListApi(false);
                    axios.post(baseUrl+"list_generator/get_table_column_values", loggedinUser.Client == "National Corn Growers Association" ? { key: props.column.dataField } : { key: props.column.dataField, "topCounts": { "associationdescription": loggedinUser.Client } }).then(result => {
                        let filt = {};
                        filt[props.column.dataField] = result.data.aaData;
                        setCustomerTableFilters(filt);
                        setFinishedCustomerListApi(true);
                    });
                }
                break;
            default:
                break;

        }
    }, []);
    if (props.table == "customerList") {
        options = (props.columnOptionParent != null ? props.columnOptionParent : []);
    } else {
        options = props.columnOptionParent.map((el) => { return el[props.column.dataField]; }).filter((value, index, self) => self.indexOf(value) === index);
        options = options.map((el) => { var obj = {}; obj[props.column.dataField] = el; return obj; });
        console.log(options);
    }
    const page = {
        sizePerPage: 100,
        pageStartIndex: 0,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
    }

    return (
        <Col sm={12} id="column-filter">
            <div style={{ float: 'right', display: 'inline' }}>
                <button onClick={() => { props.close(); clearTempFilters(); clearTempFiltersUnchecked(); }} style={{ 'background-color': 'red', 'border-radius': '4px', 'border': '1px solid red', margin: '3px' }}><i className="fa fa-times" style={{ color: 'white', 'font-size': '20px' }}></i></button>
            </div>
            <div style={{ float: 'right', display: 'inline' }}>
                <button onClick={() => { props.close(); filterSelections(); }} style={{ 'background-color': 'green', 'border-radius': '4px', margin: '3px' }}><i className="fa fa-check" style={{ color: 'white', 'font-size': '20px' }}></i></button>
            </div>
            <div>
                <input type="text" id="text-search" class="form-control" hidden placeholder="Search" />
            </div>
            <div style={{ width: '300px', overflow: 'auto' }}>

                {(props.table == "customerList" || (props.table == "topCounts" && props.column.dataField == "transactiondate")) &&
                    <BootstrapTable
                        keyField={props.column.dataField}
                        ref={n => node = n}
                        data={options}
                        columns={columns}
                        selectRow={selectRow}
                        filter={filterFactory()}
                        defaultSorted={defaultSorted}
                        pagination={paginationFactory(page)}
                    />
                }
                {props.table != "customerList" && (props.column.dataField != "transactiondate") &&
                    <BootstrapTable
                        keyField={props.column.dataField}
                        ref={n => node = n}
                        data={options}
                        columns={columns}
                        selectRow={selectRow}
                        filter={filterFactory()}
                        defaultSorted={defaultSorted}
                        noDataIndication="There is no data"
                    />
                }
            </div>
        </Col>
    );
}
