import React, { useContext, useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { Container, Row, Col } from 'react-grid-system';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import DataTable from 'react-data-table-component';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Popup from 'reactjs-popup';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';
import { XAxis, Tooltip, CartesianGrid, Bar, BarChart, Legend, YAxis, Cell, Brush } from 'recharts';

import { GlobalContext } from '../../../context/GlobalState';
import { groupByCount } from '../../../utility/utility';
import { groupBy } from '../../../utility/utility';

export default function GeographyTablesComponent() {
    const { filters,geographyByCounty, geographyByCountyFilteredData, setGeographyByCountyFilteredDataApi, setGeographyByCountyFilteredData, setFinishedGeographyByCountyApi,
        geographyByGrowerDistrict, geographyByGrowerDistrictFilteredData, setGeographyByGrowerDistrictFilteredDataApi, setGeographyByGrowerDistrictFilteredData, setFinishedGeographyByGrowerDistrictApi } = useContext(GlobalContext);
    function headerContent(column, colIndex, onFilter) {
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="bottom middle"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"geographyData"} close={close} column={column} columnOptionParent={geographyByCounty} />
                    </div>}

                </Popup>
            </div>
        );
    }
    function headerContentGrowerDistrict(column, colIndex, onFilter) {
        return (
            <div style={{ whiteSpace: 'nowrap', display: 'inline', width: '100%', float: 'left', 'min-width': '200px' }}><span style={{ float: 'left' }}>{column.text}</span>
                <Popup
                    trigger={<i style={{ marginLeft: '10px' }} className="fa fa-search"></i>}
                    position="bottom middle"
                    nested
                    style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
                >

                    {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                        <ColumnCustomFilter table={"geographyData"} close={close} column={column} columnOptionParent={geographyByGrowerDistrict} />
                    </div>}

                </Popup>
            </div>
        );
    }
    const columns = [
        {
            text: 'State', dataField: 'statename', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> },footer: 'Totals'
        },
        {
            text: 'County', dataField: 'county', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> },footer: ''
        },
        {
            text: 'Active', dataField: 'active',style:{color:"rgb(3, 169, 244)"}, sort: true,formatter: (col, row)=>{return col.toLocaleString()},footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        },
        {
            text: 'Lapsed', dataField: 'lapsed',style:{color:"rgb(142, 71, 125)"}, sort: true,formatter: (col, row)=>{return col.toLocaleString()},footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        },
        {
            text: 'Prospects', dataField: 'prospects',style:{color:"rgb(249, 63, 23)"}, sort: true,formatter: (col, row)=>{return col.toLocaleString()},footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        },
        {
            text: 'Participant', dataField: 'participant',style:{color:"rgb(243, 194, 0)"}, sort: true,formatter: (col, row)=>{return col.toLocaleString()},footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        }
    ];
    const columnsGrowerDistrict = [
        {
            text: 'State', dataField: 'statename', sort: true, headerFormatter: headerContentGrowerDistrict, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> },footer: 'Totals'
        },
        {
            text: 'Grower District', dataField: 'growerdistrict', sort: true, headerFormatter: headerContentGrowerDistrict, headerStyle: { minWidh: '200px !important' }, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> },footer: ''
        },
        {
            text: 'Active', dataField: 'active',style:{color:"rgb(3, 169, 244)"}, sort: true, formatter: (col, row)=>{return col.toLocaleString()},footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        },
        {
            text: 'Lapsed', dataField: 'lapsed',style:{color:"rgb(142, 71, 125)"}, sort: true, formatter: (col, row)=>{return col.toLocaleString()},footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        },
        {
            text: 'Prospects', dataField: 'prospects',style:{color:"rgb(249, 63, 23)"}, sort: true, formatter: (col, row)=>{return col.toLocaleString()},footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        },
        {
            text: 'Participant', dataField: 'participant', style:{color:"rgb(243, 194, 0)"},sort: true, formatter: (col, row)=>{return col.toLocaleString()},footer: (columnData, column, columnIndex) => columnData.reduce((acc, item) => acc + item, 0).toLocaleString()
        }
    ];
    const options = {

        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: 'All', value: geographyByCounty.length },
        ],
        sizePerPage: 15,
        pageStartIndex: 0,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
    }
    const optionsGrowerDistrict = {

        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: 'All', value: geographyByGrowerDistrict.length },
        ],
        sizePerPage: 15,
        pageStartIndex: 0,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
    }
    useEffect(() => {
        let localFilters = filters;
        if (localFilters.length != 0) {
            const filtersGroupedByKey = groupBy(localFilters, 'key');
            const filtersGroupByTable = groupBy(localFilters, 'filter');
            var newFilter = {};
            Object.keys(filtersGroupByTable).map((filterKey) => {
                let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                var newFilterByKey = {};
                Object.keys(filterGroupByKey).map((filKey) => {
                    let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                    newFilterByKey[filKey] = filterValues.toString();
                });
                newFilter[filterKey] = newFilterByKey;
            });
            setFinishedGeographyByCountyApi(false);
            setFinishedGeographyByGrowerDistrictApi(false);
            setGeographyByCountyFilteredDataApi(newFilter);
            setGeographyByGrowerDistrictFilteredDataApi(newFilter);
        } else {
            if (geographyByCounty.length != 0) {
                setGeographyByCountyFilteredData(geographyByCounty);
            } else {
                setFinishedGeographyByCountyApi(false);
                setGeographyByCountyFilteredDataApi([]);
            }
            if (geographyByGrowerDistrict.length != 0) {
                setGeographyByGrowerDistrictFilteredData(geographyByGrowerDistrict);
            } else {
                setFinishedGeographyByGrowerDistrictApi(false);
                setGeographyByGrowerDistrictFilteredDataApi([]);
            }
        }
    }, []);
    return (
        <div>
            <Row>
                <Col sm={6}>
                    <div id="tablediv" style={{ padding: '0px 20px 0px 20px' }}>
                        <h4 className="textalignleft">Total counts by County</h4>
                        <BootstrapTable
                            scrollX
                            keyField="name"
                            data={geographyByCountyFilteredData}
                            columns={columns}
                            pagination={paginationFactory(options)}
                            noDataIndication="There is no data"
                            width="200"
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        />
                    </div>
                </Col>
                <Col sm={6}>
                    <div id="tablediv" style={{ padding: '0px 20px 0px 20px' }}>
                        <h4 className="textalignleft">Total counts by Grower District</h4>
                        <BootstrapTable
                            scrollX
                            keyField="name"
                            data={geographyByGrowerDistrictFilteredData}
                            columns={columnsGrowerDistrict}
                            pagination={paginationFactory(optionsGrowerDistrict)}
                            noDataIndication="There is no data"
                            width="200"
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        />
                    </div>
                </Col>}
                 </Row>
        </div>
    )

}