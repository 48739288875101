import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { CSVLink } from "react-csv";
import ScrollMenu from 'react-horizontal-scrolling-menu';

import { GlobalContext } from '../../../context/GlobalState';
import { groupBy } from '../../../utility/utility';
import './FilterComponent.css';

export default function FilterComponent() {
    const { filters, newFilters, removeFilter, removeNewFilter, setHarvestFilteredData, setEntryFilteredData, selectedTabValue, entryFilteredValues, harvestFilteredValues, entryValues, harvestValues, setEntryIds, filteredIds, tabValue, customerListValues, customerListFilteredValues, setCustomerListFilteredData, setCustomerListFilteredDataApi, dashboardData, setDashboardFilteredData, setDashboardFilteredDataApi, cornDemographicsData, cornDemographicsFilteredData, setCornDemographicsFilteredData, setCornDemographicsFilteredDataApi, soybeanDemographicsData, setSoybeanDemographicsFilteredData, setSoybeanDemographicsFilteredDataApi, cattleDemographicsData, setCattleDemographicsFilteredData, setCattleDemographicsFilteredDataApi, hogsDemographicsData, setHogsDemographicsFilteredData, setHogsDemographicsFilteredDataApi, ageDemographicsData, setAgeDemographicsFilteredData, setAgeDemographicsFilteredDataApi, setFinishedDashboardApi, setFinishedCornDemoApi, setFinishedSoyDemoApi, setFinishedCattleDemoApi, setFinishedHogsDemoApi, setFinishedAgeDemoApi, setFinishedCustomerListApi, campaignData, campaignFilteredData, setCampaignFilteredData, setCampaignFilteredDataApi, setFinishedCampaignDataApi,
        campaignResponseData, setCampaignResponseFilteredDataApi, setCampaignResponseFilteredData, setFinishedCampaignResponseApi,
        tagsAnyData, setTagsAnyFilteredDataApi, setTagsAnyFilteredData, setFinishedTagsAnyApi,
        tagsAllData, tagsAllFilteredData, setTagsAllFilteredDataApi, setTagsAllFilteredData, setFinishedTagsAllApi,
        responseDispositionData, responseDispositionFilteredData, setResponseDispositionFilteredDataApi, setResponseDispositionFilteredData, setFinishedResponseDispositionApi,
        geographyByCounty, setGeographyByCountyFilteredDataApi, setGeographyByCountyFilteredData, setFinishedGeographyByCountyApi,
        geographyByGrowerDistrict, setGeographyByGrowerDistrictFilteredDataApi, setGeographyByGrowerDistrictFilteredData, setFinishedGeographyByGrowerDistrictApi,
        legislatorsData, legislatorsFilteredData, setLegislatorsFilteredDataApi, setLegislatorsFilteredData, setFinishedLegislatorsApi, transactionDateList, expireDateList, statusList, associationList, stateList, customerTableFilters } = useContext(GlobalContext);
    const updateFilterValues = (filterKey) => {
        removeFilter(filterKey);
        removeNewFilter(filterKey);
        console.log(tabValue);
        let path = window.location.pathname;
        if (path == '/' || path == '/entry' || path == '/harvest') {
            let newHarvestFilteredData = []
            let newEntryFilteredData = []
            console.log(filters);
            console.log(Object.keys(newFilters).length);
            if (Object.keys(newFilters).length <= 1) {
                setEntryIds([]);
                setEntryFilteredData(entryValues);
                setHarvestFilteredData(harvestValues);
            } else {
                // filters.map((data) => {
                //     if (data.key !== filterKey) {
                //         if (data.filter === 'harvest') {
                //             newHarvestFilteredData = newHarvestFilteredData.concat(harvestValues.filter((d) => { return d[data.key] === data.value }))
                //         } else {
                //             newEntryFilteredData = newEntryFilteredData.concat(entryValues.filter((d) => { return d[data.key] === data.value }))
                //         }
                //     }
                // });
                let localFilters = filters;
                filters.map((filter) => {
                    if (filter.key == filterKey) {
                        localFilters = localFilters.filter(item => (item.key !== filterKey));
                    }
                });
                const filtersGroupedByKey = groupBy(localFilters, 'key');
                let newHarvestFilteredData = harvestValues;
                let newEntryFilteredData = entryValues;
                Object.keys(filtersGroupedByKey).map((filterKey) => {
                    let filterValuesHarvest = filtersGroupedByKey[filterKey].map((filterItem) => { if (filterItem.filter == "harvest") return filterItem.value });
                    if (filterValuesHarvest.length != 0) {
                        newHarvestFilteredData = newHarvestFilteredData.filter((d) => { return filterValuesHarvest.includes(d[filterKey]) });
                    }
                    let filterValuesEntry = filtersGroupedByKey[filterKey].map((filterItem) => { if (filterItem.filter == "entry") return filterItem.value });
                    if (filterValuesEntry.length != 0) {
                        newEntryFilteredData = newEntryFilteredData.filter((d) => { return filterValuesEntry.includes(d[filterKey]) });
                    }
                });
                var harvestFilterIds = newHarvestFilteredData.map((el) => { return el.EntryContestID; });
                var entryFilterIds = newEntryFilteredData.map((el) => { return el.EntryContestID; });
                var filterIds=[];
                if(newHarvestFilteredData.length==harvestValues.length){
                    filterIds=entryFilterIds;
                }else{
                    filterIds = entryFilterIds.filter(value => harvestFilterIds.includes(value));
                }
                console.log(harvestFilterIds.length);
                console.log(entryFilterIds.length);
                console.log(filterIds.length);
                //let filterIds = [...new Set([...harvestFilterIds, ...entryFilterIds])];
                if (filterIds.length > 0) {
                    setEntryIds(filterIds);
                }
                setEntryFilteredData(newEntryFilteredData);
                setHarvestFilteredData(newHarvestFilteredData);
            }
        } else {
            // let newCustomerFilteredData = [];
            if (Object.keys(newFilters).length <= 1) {
                switch (path) {
                    case "/topcounts":
                        setDashboardFilteredData(dashboardData);
                        break;
                    case "/demographics":
                        if (cornDemographicsData.length != 0) {
                            setCornDemographicsFilteredData(cornDemographicsData);
                        } else {
                            setFinishedCornDemoApi(false);
                            setCornDemographicsFilteredDataApi([]);
                        }
                        if (soybeanDemographicsData.length != 0) {
                            setSoybeanDemographicsFilteredData(soybeanDemographicsData);
                        } else {
                            setFinishedSoyDemoApi(false);
                            setSoybeanDemographicsFilteredDataApi([]);
                        }
                        if (cattleDemographicsData.length != 0) {
                            setCattleDemographicsFilteredData(cattleDemographicsData);
                        } else {
                            setFinishedCattleDemoApi(false);
                            setCattleDemographicsFilteredDataApi([]);
                        }
                        if (hogsDemographicsData.length != 0) {
                            setHogsDemographicsFilteredData(hogsDemographicsData);
                        } else {
                            setFinishedHogsDemoApi(false);
                            setHogsDemographicsFilteredDataApi([]);
                        }
                        if (ageDemographicsData.length != 0) {
                            setAgeDemographicsFilteredData(ageDemographicsData);
                        } else {
                            setFinishedAgeDemoApi(false);
                            setAgeDemographicsFilteredDataApi([]);
                        }
                        break;
                    case "/campaign":
                        if (campaignData.length != 0) {
                            setCampaignFilteredData(campaignData);
                        } else {
                            setFinishedCampaignDataApi(false);
                            setCampaignFilteredDataApi([]);
                        }
                        if (campaignResponseData.length != 0) {
                            setCampaignResponseFilteredData(campaignResponseData);
                        } else {
                            setFinishedCampaignResponseApi(false);
                            setCampaignResponseFilteredDataApi([]);
                        }
                        if (tagsAnyData.length != 0) {
                            setTagsAnyFilteredData(tagsAnyData);
                        } else {
                            setFinishedTagsAnyApi(false);
                            setTagsAnyFilteredDataApi([]);
                        }
                        if (tagsAllData.length != 0) {
                            setTagsAllFilteredData(tagsAllData);
                        } else {
                            setFinishedTagsAllApi(false);
                            setTagsAllFilteredDataApi([]);
                        }
                        if (responseDispositionData.length != 0) {
                            setResponseDispositionFilteredData(responseDispositionData);
                        } else {
                            setFinishedResponseDispositionApi(false);
                            setResponseDispositionFilteredDataApi([]);
                        }
                        break;
                    case "/geography":
                        if (geographyByCounty.length != 0) {
                            setGeographyByCountyFilteredData(geographyByCounty);
                        } else {
                            setFinishedGeographyByCountyApi(false);
                            setGeographyByCountyFilteredDataApi([]);
                        }
                        if (geographyByGrowerDistrict.length != 0) {
                            setGeographyByGrowerDistrictFilteredData(geographyByGrowerDistrict);
                        } else {
                            setFinishedGeographyByGrowerDistrictApi(false);
                            setGeographyByGrowerDistrictFilteredDataApi([]);
                        }
                        break;
                    case "/legislators":
                        if (legislatorsData.length != 0) {
                            setLegislatorsFilteredData(legislatorsData);
                        } else {
                            setFinishedLegislatorsApi(false);
                            setLegislatorsFilteredDataApi([]);
                        }
                        setDashboardFilteredData(dashboardData);
                        break;
                    case "/exportcustomerlist":
                        if (customerListValues.length != 0) {
                            setCustomerListFilteredData(customerListValues);
                        } else {
                            setFinishedCustomerListApi(false);
                            setCustomerListFilteredDataApi([]);
                        }
                        setDashboardFilteredData(dashboardData);
                        break;
                    default:
                        break;
                }
            } else {
                let localFilters = filters.filter((item) => { return item.key != filterKey });
                const filtersGroupedByKey = groupBy(localFilters, 'key');
                const filtersGroupByTable = groupBy(localFilters, 'filter');
                var newFilter = {};
                Object.keys(filtersGroupByTable).map((filterKey) => {
                    let filterGroupByKey = groupBy(filtersGroupByTable[filterKey], 'key');
                    var newFilterByKey = {};
                    Object.keys(filterGroupByKey).map((filKey) => {
                        let filterValues = filterGroupByKey[filKey].map((filterItem) => { return filterItem.value });
                        newFilterByKey[filKey] = filterValues.toString();
                    });
                    newFilter[filterKey] = newFilterByKey;
                });
                switch (path) {
                    case "/topcounts":
                        setFinishedDashboardApi(false);
                        setDashboardFilteredDataApi(newFilter);
                        break;
                    case "/demographics":
                        setFinishedCornDemoApi(false);
                        setFinishedSoyDemoApi(false);
                        setFinishedCattleDemoApi(false);
                        setFinishedHogsDemoApi(false);
                        setFinishedAgeDemoApi(false);
                        setCornDemographicsFilteredDataApi(newFilter);
                        setSoybeanDemographicsFilteredDataApi(newFilter);
                        setCattleDemographicsFilteredDataApi(newFilter);
                        setHogsDemographicsFilteredDataApi(newFilter);
                        setAgeDemographicsFilteredDataApi(newFilter);
                        break;
                    case "/campaign":
                        setFinishedCampaignDataApi(false);
                        setFinishedCampaignResponseApi(false);
                        setFinishedTagsAnyApi(false);
                        setFinishedTagsAllApi(false);
                        setFinishedResponseDispositionApi(false);
                        setCampaignFilteredDataApi(newFilter);
                        setCampaignResponseFilteredDataApi(newFilter);
                        setTagsAnyFilteredDataApi(newFilter);
                        setTagsAllFilteredDataApi(newFilter);
                        setResponseDispositionFilteredDataApi(newFilter);
                        break;
                    case "/geography":
                        setFinishedGeographyByCountyApi(false);
                        setFinishedGeographyByGrowerDistrictApi(false);
                        setGeographyByCountyFilteredDataApi(newFilter);
                        setGeographyByGrowerDistrictFilteredDataApi(newFilter);
                        break;
                    case "/legislators":
                        setFinishedLegislatorsApi(false);
                        setLegislatorsFilteredDataApi(newFilter);
                        setFinishedDashboardApi(false);
                        setDashboardFilteredDataApi(newFilter);
                        break;
                    case "/exportcustomerlist":
                        setFinishedDashboardApi(false);
                        setDashboardFilteredDataApi(newFilter);
                        setFinishedCustomerListApi(false);
                        setCustomerListFilteredDataApi(newFilter);
                        break;
                    default:
                        break;

                }
            }

        }
    }
    const MenuItem = (item) => {
        let path = window.location.pathname;
        console.log(item);
        var title = newFilters[item.item].length > 1 ? `${newFilters[item.item].length} of ${selectedTabValue === 0 ? entryValues.map((el) => { return el[item.item]; }).filter((value, index, self) => self.indexOf(value) === index).length : harvestValues.map((el) => { return el[item.item]; }).filter((value, index, self) => self.indexOf(value) === index).length}` : newFilters[item.item];
        if (path == '/' || path == '/entry' || path == '/harvest') {
            return <div style={{ width: '120px', 'font-size': '10px', 'padding': '5px' }} className="alert alert-warning floatLeft">
                <a onClick={() => { updateFilterValues(item.item) }} href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
                <p style={{ marginBottom: '0px', 'text-overflow': 'ellipsis', 'overflow': 'hidden' }} title={item.item}>{item.item} :</p> <p style={{ marginBottom: '0px', 'text-overflow': 'ellipsis', 'overflow': 'hidden' }} title={title}>{newFilters[item.item].length > 1 ? `${newFilters[item.item].length} of ${selectedTabValue === 0 ? entryValues.map((el) => { return el[item.item]; }).filter((value, index, self) => self.indexOf(value) === index).length : harvestValues.map((el) => { return el[item.item]; }).filter((value, index, self) => self.indexOf(value) === index).length}` : newFilters[item.item]}</p>
            </div>;
        } else {
            let maxCount = 0;
            console.log(item);
            if (item.item == "contactType_name") {
                maxCount = statusList.length;
            } else if (item.item == "expiredate") {
                maxCount = expireDateList.length;
            } else if (item.item == "transactiondate") {
                maxCount = transactionDateList.length;
            } else if (item.item == "associationdescription") {
                maxCount = associationList.length;
            } else if (item.item == "associationdescription") {
                maxCount = associationList.length;
            } else if (item.item == "cornrange") {
                maxCount = cornDemographicsData.length;
            } else if (item.item == "soybeansrange") {
                maxCount = soybeanDemographicsData.length;
            } else if (item.item == "agerange") {
                maxCount = ageDemographicsData.length;
            } else if (item.item == "cattlerange") {
                maxCount = cattleDemographicsData.length;
            } else if (item.item == "hogsrange") {
                maxCount = hogsDemographicsData.length;
            } else if (item.item == "campaigntype") {
                maxCount = campaignData.length;
            } else if (item.item == "campaignrange") {
                maxCount = campaignResponseData.length;
            } else if (item.item == "tag_any") {
                maxCount = tagsAnyData.length;
            } else if (item.item == "tag_all") {
                maxCount = tagsAllData.length;
            } else if (item.item == "responsedisposition") {
                maxCount = responseDispositionData.length;
            } else if (item.item == "statename") {
                maxCount = stateList.length;
            } else if (item.item == "county") {
                maxCount = geographyByCounty.map((el) => { return el[item.item]; }).filter((value, index, self) => self.indexOf(value) === index).length;
            } else if (item.item == "growerdistrict") {
                maxCount = geographyByGrowerDistrict.map((el) => { return el[item.item]; }).filter((value, index, self) => self.indexOf(value) === index).length;
            } else if (item.item.indexOf("legislator") > -1) {
                maxCount = legislatorsData.map((el) => { return el[item.item]; }).filter((value, index, self) => self.indexOf(value) === index).length;
            } else {
                maxCount = customerTableFilters[item.item] ? (customerTableFilters[item.item]).length : 0;
            }

            return <div style={{ width: '120px', 'font-size': '10px', 'padding': '5px' }} className="alert alert-warning floatLeft">
                <a onClick={() => { updateFilterValues(item.item) }} href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
                <p style={{ marginBottom: '0px', 'text-overflow': 'ellipsis', 'overflow': 'hidden' }} title={item.item}>{item.item} :</p> <p style={{ marginBottom: '0px', 'text-overflow': 'ellipsis', 'overflow': 'hidden' }} title={title}>{newFilters[item.item].length > 1 ? `${newFilters[item.item].length} of ${maxCount}` : newFilters[item.item]}</p>
            </div>;
        }
    };
    const Menu = (list) =>

        list.map(el => {
            return <MenuItem item={el} />;
        });
    const Arrow = ({ text, className }) => {
        return (
            <div
                className={className}
            >{text}</div>
        );
    };
    const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
    const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });
    const menuItems = Menu(Object.keys(newFilters));
    return (
        <div>
            <Row className="boxPadding">
                <Col sm={12}>
                    <ScrollMenu
                        data={menuItems}
                        arrowLeft={ArrowLeft}
                        arrowRight={ArrowRight}
                    />
                </Col>
                {/* <Col className="label1 colorRed" style={{width:'400px','overflow-x':'auto','max-width':'auto'}}>
                    {Object.keys(newFilters).map(item => (
                        <div style={{width:'200px','font-size':'14px'}} className="alert alert-warning floatLeft">
                            <a onClick={() => {updateFilterValues(item)}} href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
                            {item} : {newFilters[item].length > 1 ? `${newFilters[item].length} of ${selectedTabValue === 0 ? entryValues.map((el) => { return el[item]; }).filter((value, index, self) => self.indexOf(value) === index).length : harvestValues.map((el) => { return el[item]; }).filter((value, index, self) => self.indexOf(value) === index).length}` : newFilters[item]}
                        </div>
                    ))}
                </Col> */}
                {/* <Col sm={12}>
                    <CSVLink data-id={selectedTabValue} className="exportButton btn btn-success" id="btnExportEntry" filename="data.csv" data={selectedTabValue === 0 ? entryFilteredValues : harvestFilteredValues}>Export</CSVLink>
                </Col> */}
            </Row>
        </div>
    )
}