import React, { useContext, useState } from 'react';
import ReactDOM from "react-dom";
import { Container, Row, Col } from 'react-grid-system';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import DataTable from 'react-data-table-component';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Popup from 'reactjs-popup';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import ColumnCustomFilter from '../../common/ColumnCustomFilter/ColumnCustomFilter';




import { GlobalContext } from '../../../context/GlobalState';

export default function EntryTableComponent() {

    const { entryFilteredValues, setEntryFilteredData, setHarvestFilteredData, harvestFilteredValues, filters, setEntryIds, entryValues } = useContext(GlobalContext);
    function headerContent(column, colIndex, onFilter) {
        return (
            <Popup
                trigger={<div style={{ whiteSpace: 'nowrap', display:'inline',width:'100%',float:'left','min-width':'200px'}}><span style={{float:'left'}}>{column.text}</span><i style={{ marginLeft:'10px'}} className="fa fa-search"></i></div>}
                position="top left"
                nested
                style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}
            >

                {close => <div style={{ padding: '10px', border: '1px solid black', background: '#fff', margin: '20px' }}>
                    <ColumnCustomFilter table={"entry"} close={close} column={column} columnOptionParent={entryValues}/> 
                    </div>}

            </Popup>

        );
    }
    const columns = [ 
        {
            text: 'Entry Code', dataField: 'EntryCode', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' } ,formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Entry Contest ID', dataField: 'EntryContestID', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },         
        {
            text: 'Entry Status', dataField: 'EntryStatus', sort: true, headerFormatter: headerContent, headerStyle: { minWidh: '200px !important' } ,formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Create Date', dataField: 'CreateDate', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Complete Date', dataField: 'CompleteDate', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Member ID', dataField: 'MemberID', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Display Name', dataField: 'DisplayName', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'First Name', dataField: 'FirstName', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Last Name', dataField: 'LastName', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Suffix', dataField: 'Suffix', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Business', dataField: 'Business', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Address', dataField: 'Address', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },        
        {
            text: 'City', dataField: 'City', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'State', dataField: 'STATE', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Postal Code', dataField: 'PostalCode', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'County', dataField: 'County', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Home', dataField: 'Home', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Work', dataField: 'Work', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Mobile', dataField: 'Mobile', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Email Address', dataField: 'EmailAddress', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        }, 
        {
            text: 'Membership County', dataField: 'MembershipCounty', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        }, 
        {
            text: 'Field State', dataField: 'FieldState', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },     
        {
            text: 'Class', dataField: 'Class', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },                                                                               
        {
            text: 'Hybrid Brand', dataField: 'HybridBrand', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Hybrid Number', dataField: 'HybridNumber', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Trait', dataField: 'Trait', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Contest Dues', dataField: 'ContestDues', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },  
        {
            text: 'Contest Dues Payment Method', dataField: 'ContestDuesPaymentMethod', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },
        {
            text: 'Discount', dataField: 'Discount', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        }, 
        {
            text: 'Member Dues', dataField: 'MemberDues', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        }, 
        {
            text: 'Member Dues Payment Method', dataField: 'MemberDuesPaymentMethod', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },             
        {
            text: 'Voucher', dataField: 'Voucher', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        },                                              
        {
            text: 'Sales Rep', dataField: 'SalesRep', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        }       
    ];
    if(sessionStorage.getItem("Client")=="Pioneer"||sessionStorage.getItem("UserRole")=="National Admin"){
        columns.push({
            text: 'Sales Rep ID#', dataField: 'SalesRepID', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        });
    }
    if(sessionStorage.getItem("Client")=="DEKALB"||sessionStorage.getItem("UserRole")=="National Admin"){
        columns.push({
            text: 'MTSA #', dataField: 'MTSA', sort: true, headerFormatter: headerContent, formatter: (col, row) => { return <span style={{ display: 'block', width: 'auto', overflow: 'auto', whiteSpace: 'nowrap', }}>{col}</span> }
        });
    }
    const options = {
        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
            { text: 'All', value: entryFilteredValues.length },
        ],
        sizePerPage: 5,
        pageStartIndex: 0,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
    }
    function afterFilter(newResult, newFilter) {
        if (filters.length === 0) {
            setEntryIds([]);
        } else {
            var filterIds = newResult.map((el) => { return el.EntryContestID; });
            setEntryIds(filterIds);
        }
        setEntryFilteredData(newResult)
        setHarvestFilteredData(harvestFilteredValues)
    }
    return (
        <div>
            <Col sm={12}>
                <div id="tablediv" style={{ padding: '20px' }}>
                    {/* <DataTable
                        pagination
                        paginationPerPage={10}
                        title="Entry Details"
                        columns={columns}
                        data={entryFilteredValues}
                    /> */}
                    <BootstrapTable
                        scrollX
                        keyField="name"
                        data={entryFilteredValues}
                        columns={columns}
                        filter={filterFactory({ afterFilter })}
                        pagination={paginationFactory(options)}
                        noDataIndication="There is no data"
                        width="200"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    />
                </div>
            </Col>
        </div>
    )
}